export class ServiceLocation {
  id: number;
  showAlias: boolean;
  alias?: string;
  city: string;
  street: string;
  houseNumber: string;
  zip: string;
  displayName: string;
  showTireStorage: boolean;
  active: boolean;
  groupId: number;
  customerSpecialServiceLocationId: any[];
  myNatureContractNumber: string;
  myNaturePartnerNumber: string;
  locationOrderNumber?: string;
  locationOrderNumberTwo?: string;
  locationGLNNumber?: string;
  myNatureShipToNumber: string;
  myNatureShipToName: string;
  latitude: string;
  longitude: string;
  createdOn: string;
  constructor(dto: any) {
    this.id = dto.id;
    this.showAlias = dto.showAlias;
    this.alias = dto.alias;
    this.city = dto.city;
    this.street = dto.street;
    this.houseNumber = dto.houseNumber;
    this.zip = dto.zip;
    this.showTireStorage = dto.showTireStorage;
    this.locationOrderNumber = dto.locationOrderNumber;
    this.displayName =
      this.showAlias && this.alias
        ? `${this.locationOrderNumber ? this.locationOrderNumber + ", " : ""}${
          this.alias
        }, ${this.street} ${this.houseNumber}, ${this.zip} ${this.city}`
        : `${this.locationOrderNumber ? this.locationOrderNumber + ", " : ""}${
          this.street
        } ${this.houseNumber}, ${this.zip} ${this.city}`;
    this.customerSpecialServiceLocationId =
      dto.customerSpecialServiceLocationId;
    this.active = dto.active;
    this.myNatureContractNumber = dto.myNatureContractNumber;
    this.myNaturePartnerNumber = dto.myNaturePartnerNumber;
    this.locationOrderNumberTwo = dto.locationOrderNumberTwo;
    this.locationGLNNumber = dto.locationGLNNumber;
    this.groupId = dto.groupId;
    this.latitude = dto.latitude;
    this.longitude = dto.longitude;
    this.myNatureShipToNumber = dto.myNatureShipToNumber;
    this.myNatureShipToName = dto.myNatureShipToName;
    this.createdOn = dto.createdOn;
  }
}
export class Agreement {}
export class AgreementType {}
export class ContainerType {
  id!: number;
  type!: string;
  size!: string;
  isBigbagFamily!: boolean;
  specialOwnCondition!: boolean;
}
export class ContainerAction {}

export class WasteContainerLogic {
  id!: number;
  containerTypeId!: number;
  wasteTypeId?: number;
}
export class WasteType {
  id!: number;
  description!: string;
  showOffer!: boolean;
  containerTypeMappings!: WasteContainerLogic[];
}

export class ServiceLocationDetail extends ServiceLocation {
  country: string;
  externalSystemId?: string;
  showTireStorage: boolean;
  state: string;
  agreements: any[]; //Agreement[]
  contactUserId?: number;
  externalContactUserId: number;
  externalContactUser: object;
  routeSketchFirst?: string;
  routeSketchSecond?: string;
  routeSketchThird?: string;
  locationOrderNumber?: string;
  locationOrderNumberTwo?: string;
  orderNumberThree?: string;
  locationGLNNumber?: string;
  companyId: number;
  myNatureContractNumber: string;
  myNaturePartnerNumber: string;
  myNatureShipToNumber: string;
  myNatureShipToName: string;
  latitude: string;
  longitude: string;
  constructor(dto: any) {
    super(dto);
    this.country = dto.country;
    this.externalSystemId = dto.externalSystemId;
    this.showTireStorage = dto.showTireStorage;
    this.state = dto.state;
    this.agreements = dto.agreements;
    this.contactUserId = dto.contactUserId;
    this.externalContactUserId = dto.externalContactUserId;
    this.externalContactUser = dto.externalContactUser;
    this.routeSketchFirst = dto.routeSketchFirst;
    this.routeSketchSecond = dto.routeSketchSecond;
    this.routeSketchThird = dto.routeSketchThird;
    this.locationOrderNumber = dto.locationOrderNumber;
    this.locationOrderNumberTwo = dto.locationOrderNumberTwo;
    this.orderNumberThree = dto.orderNumberThree;
    this.locationGLNNumber = dto.locationGLNNumber;
    this.companyId = dto.companyId;
    this.myNatureContractNumber = dto.myNatureContractNumber;
    this.myNaturePartnerNumber = dto.myNaturePartnerNumber;
    this.myNatureShipToNumber = dto.myNatureShipToNumber;
    this.myNatureShipToName = dto.myNatureShipToName;
    this.latitude = dto.latitude;
    this.longitude = dto.longitude;
  }
}
