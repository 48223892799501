
import Vue from "vue";
import { EventBus } from "@/helpers";
import Component from "vue-class-component";

@Component
export default class AppUpdate extends Vue {
  registration = null as any;
  updateExists = false;
  refreshing = false;
  updateMessage = "";

  created() {
    this.registerEvents();
  }

  refreshApp() {
    this.updateExists = false;
    // this makes the new serviceworker take over, above event controllerchange is then being fired
    if (this.registration && this.registration.waiting) {
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      return;
    }
  }

  registerEvents() {
    // Events fired from registerServiceWorker.ts
    EventBus.$on("tb-sw", (message: any) => {
      this.$toast.info(message, {
        queueable: true,
        y: "bottom",
        timeout: 1500,
      });
    });
    
    // Event fired from registerServiceWorker.ts
    EventBus.$once("tb-update", (msg: string, data: any) => {
      this.registration = data.detail;
      this.updateMessage = msg;
      this.updateExists = true;
      window.setTimeout(this.refreshApp, 3000);
    });
    // Native ServiceWorker Event after Update
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // Prevent multiple refreshes
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }
  }
}
