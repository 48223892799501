
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data: () => ({}),
  props: ["showMenu"],
  computed: {
    ...mapState({
      inMaintenance: (state: any) => state.app.inMaintenance,
    }),
  },
});
