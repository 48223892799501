import _Vue from "vue"; // <-- notice the changed import
import { bootstrap } from 'vue-gtag'

enum CookieValue {
  ACCEPT = "accepted",
  REJECT = "rejected",
}


export const cookiesPlugin = {
  isAccepted: () => {
    return window.localStorage.getItem("cookies") === CookieValue.ACCEPT
  },
  isUndecided: () => {
    return window.localStorage.getItem("cookies") === null
  },
  accept: () => {
    window.localStorage.setItem("cookies", CookieValue.ACCEPT)
  },
  reject: () => {
    window.localStorage.setItem("cookies", CookieValue.REJECT)
  }
}

export function Cookies(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$cookies = cookiesPlugin;
}
