import { ApiUrl } from "@/config/ApiUrl";
import { DocumentTypes } from "@/helpers";
import axios from "axios";
interface GetDocumentsRequest {
  serviceLocationIds: number[];
  startDate?: string;
  endDate?: string;
  documentTypes: string[];
  limit?: number;
  offset?: number;
  orderBy?: string;
}

export const documentModule = {
  state: () => ({
    mainInvoices: [], // main invoices
    serviceInvoices: [],
    creditNotes: [],
    cancellationInvoices: [],
    cancellationCreditNotes: [],
    totalRecords: 0,
  }),
  mutations: {
    setMainInvoices(state: any, mainInvoices: any) {
      state.mainInvoices = mainInvoices;
    },
    setServiceInvoices(state: any, serviceInvoices: any) {
      state.serviceInvoices = serviceInvoices;
    },
    setCreditNotes(state: any, creditNotes: any) {
      state.creditNotes = creditNotes;
    },
    setCancellationInvoices(state: any, cancellationInvoices: any) {
      state.cancellationInvoices = cancellationInvoices;
    },
    setCancellationCreditNotes(state: any, cancellationCreditNotes: any) {
      state.cancellationCreditNotes = cancellationCreditNotes;
    },
    setTotalRecords(state: any, totalRecords: number) {
      state.totalRecords = totalRecords;
    },
  },
  actions: {
    async getDocuments({ commit }: any, payload: GetDocumentsRequest) {
      const mutationMap = {
        [DocumentTypes.MAIN_INVOICE as string]: "setMainInvoices",
        [DocumentTypes.SERVICE_INVOICE as string]: "setServiceInvoices",
        [DocumentTypes.CREDIT_NOTE as string]: "setCreditNotes",
        [DocumentTypes.CANCEL_INVOICE as string]: "setCancellationInvoices",
        [DocumentTypes.CANCEL_CREDIT_NOTE as string]:
          "setCancellationCreditNotes",
      };
      const selectedType = payload.documentTypes[0];
      if (
        payload.serviceLocationIds.length === 0 ||
        (payload.serviceLocationIds.length === 1 &&
          payload.serviceLocationIds[0] > 0)
      ) {
        const documents = await axios.post(ApiUrl.GET_DOCUMENTS, payload);
        commit(mutationMap[selectedType], documents.data.documents);
        commit("setTotalRecords", documents.data.totalRecords);
      } else {
        commit(mutationMap[selectedType], []);
      }
    },
  },
  getters: {
    mainInvoices(state: any) {
      return state.mainInvoices;
    },
    serviceInvoices(state: any) {
      return state.serviceInvoices;
    },
    creditNotes(state: any) {
      return state.creditNotes;
    },
    cancellationInvoices(state: any) {
      return state.cancellationInvoices;
    },
    cancellationCreditNotes(state: any) {
      return state.cancellationCreditNotes;
    },
    totalRecords(state: any) {
      return state.totalRecords;
    },
  },
};
