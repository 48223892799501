import { VuetifyToastOptions } from "@/config/VuetifyToastOptions";
import VueGtm from "@gtm-support/vue2-gtm";
import "@mdi/font/css/materialdesignicons.css";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import Axios from "axios";
import PortalVue from "portal-vue";
import Vue from "vue";
import VueGtag from "vue-gtag";
import Vuetify from "vuetify";
import VuetifyToast from "vuetify-toast-snackbar-ng";
import App from "./App.vue";
import { Cookies } from "./plugins/cookies";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue: Vue,
    logErrors: true,
    ignoreErrors: [
      /^.*401.*$/,
      /^.*403.*$/,
      /^.*properties of.*$/,
      /^.*navigation.*$/,
      /^.*undefined.*$/,
      /^.*Could not load.*$/,
      /^.*timeout.*$/,
    ],
    enabled: true,
    environment: process.env.NODE_ENV,
    dsn: "https://035b480cc15b4e2796f87d5c15c5544a@o499743.ingest.sentry.io/5578591",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control // default 1.0
    tracesSampleRate: 0.1,
  });
}

Vue.config.productionTip = false;
Vue.use(Vuetify, { iconfont: "md" });
Vue.use(VuetifyToast, VuetifyToastOptions);
Vue.use(Cookies);
Vue.use(PortalVue);

Vue.use(
  VueGtag,
  {
    config: { id: "G-WZPDDSTC4S" },
    appName: "ZentekConnect",
    pageTrackerScreenviewEnabled: true,
    bootstrap: false,
  },
  router
);

Vue.use(VueGtm, {
  id: "GTM-TP7V8N9",
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: Vue.prototype.$cookies.isAccepted(), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional),
  router: router, // Pass in the router instance to automatically track page changes (optional)
});

// axios set-up: token from localStorage && User-Reload initialization
Vue.prototype.$http = Axios;
const token = window.localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
  store.dispatch("loginFromLocalStore", window.localStorage.getItem("user"));
}

Vue.directive("disabledIconFocus", {
  bind: function (el) {
    el.querySelectorAll(".v-input__icon button").forEach((input) =>
      input.setAttribute("tabindex", "-1")
    );
  },
});

const x = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

//@ts-ignore
if (window.Cypress) {
  // Add app to the window object only when testing with Cypress
  //@ts-ignore
  window.app = x;
}
