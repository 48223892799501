import Vue from "vue";
export const EventBus = new Vue();

export const emailRules = [
  (email: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      email
    ) || "Bitte geben Sie eine gültige E-Mail ein.",
];

/* Validatation for input fields Rules */
export function validateEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
}

export function validateGLNnummer(GLNnummer: string) {
  return /^\d{13,}$/.test(GLNnummer);
}

export function validatePhone(phone: string) {
  return /^[\d\s/()+#,-:]{3,20}$/.test(phone);
}

export function validateName(name: string) {
  // for international customers .
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,30}$/.test(
    name
  );
}
export function formatPhoneNumber(phoneNumber: string) {
  // keep only numbers and specified symbols
  return (
    phoneNumber
      // eslint-disable-next-line no-useless-escape
      .replace(/[^0-9\/()+#,\-\s:.]+/g, "")
  );
}

export const passwdRules = [
  (passwd: string) =>
    /.{8,}/.test(passwd) || "Das Passwort muss min. 8 Zeichen lang sein.",
  (passwd: string) => /[0-9]/.test(passwd) || "Eine Zahl muss enthalten sein.",
  (passwd: string) =>
    /[a-z]/.test(passwd) || "Ein Buchstabe muss enthalten sein.",
  (passwd: string) =>
    /[A-Z]/.test(passwd) || "Ein Großbuchstabe muss enthalten sein.",
];

// export const passwdRules = [(passwd: string) => /.{4,}/.test(passwd) || "Das Passwort ist zu kurz."];

export function dateFormat(date: string): string {
  return new Date(date).toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}
export function dateTimeFormat(date: string): string {
  return new Date(date).toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Berlin",
  });
}
export function formatDate(val: string) {
  const date = new Date(val);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return dd + "/" + mm + "/" + yyyy;
}

export function orderCanGo(desiredDateString: string) {
  let canGo = true;
  const currentTimeDate = new Date();
  const desiredDate = new Date(desiredDateString);
  desiredDate.setHours(11, 59, 59, 59);
  // check if current time is after 12:00 or if it is a weekend in german timezone
  const germanTimeString = currentTimeDate.toLocaleTimeString("de-DE", {
    timeZone: "Europe/Berlin",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const germanDateString = currentTimeDate.toLocaleDateString("de-DE", {
    weekday: "long",
    timeZone: "Europe/Berlin",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  currentTimeDate.setDate(
    parseInt(germanDateString.split(",")[1].split(".")[0])
  );
  currentTimeDate.setMonth(
    parseInt(germanDateString.split(",")[1].split(".")[1]) - 1
  );
  currentTimeDate.setHours(
    parseInt(germanTimeString.split(":")[0]),
    parseInt(germanTimeString.split(":")[1]),
    parseInt(germanTimeString.split(":")[2])
  );
  const currentGermanDay = germanDateString.split(",")[0];
  const currentGermanHour = parseInt(germanTimeString.split(":")[0]);

  const isWeekend =
    currentGermanDay === "Samstag" || currentGermanDay === "Sonntag";
  const isAfter12 = currentGermanHour >= 12;
  const dayDiff = Math.ceil(
    (desiredDate.getTime() - currentTimeDate.getTime()) / (1000 * 3600 * 24)
  );

  if (isAfter12) {
    if (!isWeekend && currentGermanDay !== "Freitag" && dayDiff < 2) {
      canGo = false;
    }
    if (currentGermanDay === "Samstag" && dayDiff < 3) {
      canGo = false;
    }
    if (currentGermanDay === "Sonntag" && dayDiff < 2) {
      canGo = false;
    }
    if (currentGermanDay === "Freitag" && dayDiff < 4) {
      canGo = false;
    }
  }
  return canGo;
}

export const ContainerType = Object.freeze({
  ALTOTANK: 29,
  LOGBOX: 21,
  LOSE_ABHOLUNG: 1,
  MINI_CONTAINER: 10,
  PALETTE: 28,
  ROLLCONTAINER: 2,
  SPANNRINGDECKELFASS: 30,
  SPANNRINGFASS: 76,
  SPRAYDOSENFASS: 35,
  SPUNDLOCHFASS: 31,
  ABROLLCONTAINER: [
    4, 13, 14, 15, 38, 42, 43, 44, 45, 46, 47, 100, 101, 102, 103, 104, 105,
    106, 107, 108, 109, 110, 111, 112, 113, 114, 116, 117, 118, 119, 120, 150,
    179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193,
    194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208,
    209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 245, 246, 247,
  ],
  ABROLLPRESSE: [58, 59, 60, 61, 62, 63],
  ABSETZCONTAINER: [
    3, 11, 12, 36, 40, 41, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
    93, 94, 95, 96, 97, 98, 99, 133, 152, 153, 154, 155, 156, 157, 158, 159,
    160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173,
  ],
  ABSETZPRESSE: [56, 57],
  ASF: [32, 33],
  ASP: 34,
  BIGBAG: 22,
  BALLENPRESSE: 64,
  DATENSCHUTZTONNE: [53, 54],
  GLITTERBOX: [19, 20],
  PRESSCONTAINER: [17, 18, 37],
  SAMMELSACK: [23, 24, 72, 71, 73, 74],
  UMLEER_BEHALTER: [5, 6, 48, 7, 49, 8, 65, 50, 66, 67, 68, 69, 70],
  FOLIENSACK: [51, 52],
  LEUCHSTOFFKARTON: 55,
  EUROPALETTE: 75,
  KOPPEL: [77, 78],
  STATIONARY: 79,
  ABSAUGEN_AUS_TANK: 132,
  BIGBAG_AND_FRIENDS: [
    1, 22, 23, 24, 72, 71, 73, 74, 75, 51, 52, 55, 132, 374, 375, 487, 488, 489,
    494, 497, 501, 535, 536, 582,
  ],
  ABHOLUNG_ONLY: [75],
});

export const LocationManager = 22;
export const UserManager = 17;
export const OrderManager = 14;
export const AgreementManager = 13;
export const StandardUser = 11;
export const DocumentManager = 24;
export const DocumentViewer = 23;
export const ExportManager = 16;

export const ContainerAction = Object.freeze({
  ABHOLEN: 1,
  TAUSCHEN: 2,
  ABZIEHEN: 3,
  LEEREN: 4,
  STELLEN: 5,
  KAUF: 6,
  SOFORTBEFÜLLUNG: 7,
  ABSAUGEN: 9,
  LEEREN_KUNDENEIGEN: 10,
  UMSETZEN_MIT_SEPARATER_ANFAHRT: 11,
});

export const TurnusInterval = Object.freeze({
  WEEKLY: "Wöchentlich",
  TWOTIMES_PER_WEEK: "2x pro Woche",
  EVERY_14_DAYS: "14-tägig",
  EVERY_4_WEEKS: "4 Wochen",
  EVERY_6_WEEKS: "6 Wochen",
  EVERY_8_WEEKS: "8 Wochen",
});

export const TurnusDay = Object.freeze({
  ANYDAY: "Egal",
  MONDAY: "Montag",
  TUESDAY: "Dienstag",
  WEDNESDAY: "Mittwoch",
  THURSDAY: "Donnerstag",
  FRIDAY: "Freitag",
});

export const Enum_Ebenes = Object.freeze({
  Zentrale: 1,
  Großgebiet: 2,
  Gebiet: 3,
  Standort: 4,
});
/** IE <11 Polyfill for new Event */
(function () {
  if (typeof Event !== "function") return false; //If not IE

  function CustomEvent(
    event: string,
    params: {
      bubbles: boolean;
      cancelable: boolean;
      detail: undefined | null | string | [] | object;
    }
  ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  // @ts-ignore
  window.Event = CustomEvent;
})();

export function toastError(that: any, error: any, message: string) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.statusCode &&
    error.response.data.error.details &&
    error.response.data.error.statusCode === 500
  ) {
    that.$toast.error(error.response.data.error.details);
  } else {
    that.$toast.error(message);
  }
}

export enum TurnusIntervalId {
  YEARLY = 1,
  TWICE_A_YEAR = 2,
  THREE_TIMES_A_YEAR = 3,
  EVERY_QUARTAL = 4,
  EVERY_TWO_MONTHS = 5,
  EVERY_SEVEN_WEEKS = 6,
  EVERY_SIX_WEEKS = 7,
  EVERY_FIVE_WEEKS = 8,
  EVERY_THIRTEEN_WEEKS = 9,
  EVERY_EIGHT_WEEKS = 10,
  MONTHLY = 11,
  EVERY_FOUR_WEEKS = 12,
  EVERY_TWELVE_WEEKS = 13,
  EVERY_THREE_WEEKS = 14,
  EVERY_FIVE_MONTHS = 15,
  EVERY_FOUTEEN_DAYS = 16,
  THREE_TIMES_A_MONTH = 17,
  TWICE_A_MONTH = 18,
  WEEKLY = 19,
  TWICE_A_WEEK = 20,
  THREE_TIMES_A_WEEK = 21,
  FOUR_TIMES_A_WEEK = 22,
  FIVE_TIMES_A_WEEK = 23,
  SIX_TIMES_A_WEEK = 24,
  EVERY_WORKING_DAY = 26,
  FIVE_TIMES_A_MONTH = 28,
  EVERY_TWO_DAYS = 29,
}

export enum DocumentTypes {
  MAIN_INVOICE = "Rechnung",
  CREDIT_NOTE = "Gutschrift",
  CANCEL_INVOICE = "Storno-Rechnung",
  CANCEL_CREDIT_NOTE = "Storno-Gutschrift",
  SERVICE_INVOICE = "Leistungsbelege",
}

export const appEnv = process.env.NODE_ENV;

export function getAgreementOpenOrdersInfo(agreement: any) {
  const ordersInfo = {
    abholen: { amount: 0, claimed: false },
    tauschen: { amount: 0, claimed: false },
    abziehen: { amount: 0, claimed: false },
    leeren: { amount: 0, claimed: false },
    stellen: { amount: 0, claimed: false },
    kaufen: { amount: 0, claimed: false },
    sofort: { amount: 0, open: 0, claimed: false },
    absaugen: { amount: 0, claimed: false },
    umsetzen: { amount: 0, claimed: false },
    zwischenLeerung: {
      amount: 0,
      claimed: false,
    },
    empty: true,
  };
  for (const order of agreement.orders) {
    switch (order.containerAction.id) {
      case 1:
        {
          ordersInfo.abholen.amount += 1;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.abholen.claimed = true;
              }
            }
          }
        }
        break;
      case 2:
        {
          const isZwischenLeerung =
            order.selectedIntervalId !== 0 || agreement.agreementTypeId !== 4
              ? false
              : true;
          ordersInfo.zwischenLeerung.amount +=
            isZwischenLeerung === true ? 1 : 0;
          ordersInfo.tauschen.amount += isZwischenLeerung !== true ? 1 : 0;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.tauschen.claimed =
                  isZwischenLeerung !== true ? true : false;
                ordersInfo.zwischenLeerung.claimed =
                  isZwischenLeerung === true ? true : false;
              }
            }
          }
        }
        break;
      case 3:
        {
          ordersInfo.abziehen.amount += 1;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.abziehen.claimed = true;
              }
            }
          }
        }
        break;
      case 4:
      case 10:
        {
          const isZwischenLeerung =
            order.selectedIntervalId !== 0 || agreement.agreementTypeId !== 4
              ? false
              : true;
          ordersInfo.zwischenLeerung.amount +=
            isZwischenLeerung === true ? 1 : 0;
          ordersInfo.leeren.amount += isZwischenLeerung !== true ? 1 : 0;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.leeren.claimed =
                  isZwischenLeerung !== true ? true : false;
                ordersInfo.zwischenLeerung.claimed =
                  isZwischenLeerung === true ? true : false;
              }
            }
          }
        }
        break;
      case 5:
        {
          ordersInfo.stellen.amount += 1;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.stellen.claimed = true;
              }
            }
          }
        }
        break;
      case 6:
        {
          ordersInfo.kaufen.amount += 1;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.kaufen.claimed = true;
              }
            }
          }
        }
        break;
      case 7:
        {
          ordersInfo.sofort.amount += 1;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.sofort.claimed = true;
              }
            }
          }
          if (order.orderStatusId === 1) {
            ordersInfo.sofort.open += 1;
          }
        }
        break;
      case 9:
        {
          const isZwischenLeerung =
            order.selectedIntervalId !== 0 || agreement.agreementTypeId !== 4
              ? false
              : true;
          ordersInfo.zwischenLeerung.amount +=
            isZwischenLeerung === true ? 1 : 0;
          ordersInfo.absaugen.amount += isZwischenLeerung !== true ? 1 : 0;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.absaugen.claimed =
                  isZwischenLeerung !== true ? true : false;
                ordersInfo.zwischenLeerung.claimed =
                  isZwischenLeerung === true ? true : false;
              }
            }
          }
        }
        break;
      case 11:
        {
          ordersInfo.umsetzen.amount += 1;
          ordersInfo.empty = false;
          if (order.claims && order.claims.length > 0) {
            for (const claim of order.claims) {
              if (!claim.closedOn) {
                ordersInfo.umsetzen.claimed = true;
              }
            }
          }
        }
        break;
    }
  }

  return ordersInfo;
}

export function containerActionNoun(order: any) {
  if (
    order.containerAction &&
    order.containerAction.description === "Zwischenleerung"
  ) {
    return order.containerAction.description;
  } else {
    switch (order.containerActionId) {
      case ContainerAction.STELLEN:
        return "Stellen";
      case ContainerAction.TAUSCHEN:
        return "Tausch";
      case ContainerAction.ABZIEHEN:
        return "Abzug";
      case ContainerAction.LEEREN:
      case ContainerAction.LEEREN_KUNDENEIGEN:
        return "Leeren";
      case ContainerAction.KAUF:
        return "Kauf";
      case ContainerAction.SOFORTBEFÜLLUNG:
        return "Sofortbefüllung";
      case ContainerAction.UMSETZEN_MIT_SEPARATER_ANFAHRT:
        return "Umsetzen (separate Anfahrt)";
      default:
        return "Abholung";
    }
  }
}

export const specialWasteTypes = [
  7000, 7002, 7021, 7022, 7023, 7024, 7025, 7026, 7027, 7028, 7029, 7031, 7036,
  7037, 7041, 7044, 7046, 7050, 7051, 7052, 7057, 7061, 7064, 7065, 7078, 7079,
  7082, 7085, 7092, 7119, 7122, 7123, 7126, 7127, 7128, 7152, 7156, 7157, 7160,
  7161, 7165, 7169, 7170, 7171, 7172, 7173, 7178, 7180, 7189, 7198, 7202, 7203,
  7204, 7205, 7222, 7224, 7225, 7228, 7229, 7237, 7255, 7271, 7273, 7278, 7284,
  7301, 7302, 7304, 7305, 7309, 7316, 7326, 7333, 7337, 7338, 7346, 7349, 7350,
  7351, 7359, 7368, 7370, 7377, 7382, 7401, 7408, 7421, 7422, 7428, 7444, 7452,
  7466, 7470, 7483, 7484, 7487, 7489, 7499, 7415, 7417, 7420, 7427, 7429, 7497,
  7508,
];

export function addWorkingDays(date: Date, numDays: number): Date {
  let count = 0;

  while (count < numDays) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      count++;
    }
  }

  return new Date(date.getTime());
}

export enum AgreementType {
  EINMALAUFTRAG = 1,
  SOFORTBEFULLUNG = 2,
  DAUERAUFTRAG_AUF_ABRUF = 3,
  DAUERAUFTRAG_IM_TURNUS = 4,
}
