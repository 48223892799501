
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MaintenancePage",
  computed: {
    ...mapGetters({
      maintenanceEndDate: "maintenanceEndDate",
      maintenanceEndTime: "maintenanceEndTime",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
    maintenanceTimeWords(){
      if(this.maintenanceEndTime && this.maintenanceEndTime !== null && this.maintenanceEndTime !== '23:59'){
        return 'um ' + this.maintenanceEndTime + ' Uhr';
      }
      return 'im Laufe des Tages';
    }
  },
});
