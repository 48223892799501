import { ApiUrl } from "@/config/ApiUrl";
import axios from "axios";

export interface Company {
  id: number;
  name: string;
  costCenterNumber?: number;
}
export const userModule = {
  state: () => ({
    user: {
      firstName: "",
      lastName: "",
      authRoleIds: [],
      ebeneId: 0,
      companyEbeneRoles: [],
      id: 0,
      groupId: 0,
      email: "",
      phone: "",
      gender: "",
      profilePicture: "",
      companyId: 0,
      companyName: "",
      firstLogin: false,
      userIsUserManager: false,
      userIsLocationManager: false,
      userIsAgreementManager: false,
      userIsOrderManager: false,
      userIsDocumentManager: false,
      userIsExportManager: false,
      userIsDocumentViewer: false,
      initialPassword: false,
    },
    token: "",
    refreshToken: "",
    resetTaskNum: 0,
    companyHandler: {
      isMultipleCompanies: false,
      isFirstLayerAuthorized: false,
      companies: [] as Company[],
    },
    externalAppConfig: {
      email: undefined,
      password: undefined,
      isWebView: false,
    },
    hasLoggedInByInjectedData: false,
  }),
  mutations: {
    usedAutoLogin(state: any) {
      state.hasLoggedInByInjectedData = true;
    },
    clearAutoLoginState(state: any) {
      state.externalAppConfig.email = undefined;
      state.externalAppConfig.password = undefined;
    },
    setAutoLogin(state: any, injectedData: any) {
      state.externalAppConfig = injectedData;
    },
    setCompanyHandler(state: any, companyHandler: any) {
      state.companyHandler = companyHandler;
    },
    setFirstLayerUserInfo(state: any, payload: any) {
      state.user.firstName = payload.firstName;
      state.user.lastName = payload.lastName;
      state.user.id = payload.userId;
      state.user.authRoleIds = payload.roles || [];
      state.user.ebeneId = payload.ebeneId;
      state.user.email = payload.email;
      state.user.gender = payload.gender;
      state.user.phone = payload.phone;
      state.user.profilePicture = payload.profilePicture;
      state.user.firstLogin = payload.firstLogin;
      state.user.initialPassword = payload.initialPassword;
    },
    authSuccess(state: any, payload: any) {
      state.token = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.user.companyEbeneRoles = payload.companyEbeneRoles || [];
      state.user.firstName = payload.firstName;
      state.user.lastName = payload.lastName;
      state.user.id = payload.userId;
      state.user.authRoleIds = payload.roles || [];
      state.user.ebeneId = payload.ebeneId;
      state.user.email = payload.email;
      state.user.gender = payload.gender;
      state.user.phone = payload.phone;
      state.user.profilePicture = payload.profilePicture;
      state.user.firstLogin = payload.firstLogin;
      state.user.initialPassword = payload.initialPassword;
      state.user.groupId = payload.groupId;
      state.user.companyId = payload.companyId;
      state.user.companyName = payload.companyName;
      state.user.userIsLocationManager =
        payload.companyEbeneRoles && payload.companyEbeneRoles.includes(22);
      state.user.userIsUserManager =
        payload.roles && payload.roles.includes(17);
      state.user.userIsAgreementManager =
        payload.roles && payload.roles.includes(13);
      state.user.userIsOrderManager =
        payload.roles && payload.roles.includes(14);
      state.user.userIsDocumentManager =
        payload.companyEbeneRoles && payload.companyEbeneRoles.includes(24); //can see EVERYTHING in Belege menu
      state.user.userIsExportManager =
        payload.roles && payload.roles.includes(16);
      state.user.userIsDocumentViewer =
        payload.companyEbeneRoles && payload.companyEbeneRoles.includes(23);
    },
    logout(state: any) {
      state.token = "";
      state.refreshToken = "";
      state.user = { id: 0 };
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    },
    updateProfile(state: any, payload: any) {
      state.user.firstName = payload.firstName;
      state.user.lastName = payload.lastName;
      state.user.phone = payload.phone;
      state.user.gender = payload.gender;
      state.user.profilePicture = payload.profilePicture;
    },
    resetToken(state: any, payload: any) {
      state.token = payload.accessToken;
    },
    resetTokenTask(state: any, taskNumber: number) {
      state.resetTaskNum = taskNumber;
    },
  },
  actions: {
    resetToken({ commit, dispatch }: any) {
      const data = { refreshToken: localStorage.getItem("refreshToken") };

      return new Promise((resolve, reject) => {
        axios({ url: ApiUrl.REFRESH_TOKEN, data: data, method: "POST" })
          .then((apiResponse: any) => {
            if (apiResponse.request.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              reject(apiResponse);
              return apiResponse;
            }
            const token = apiResponse.data.accessToken;
            localStorage.setItem("token", token);
            commit("resetToken", apiResponse.data);
            // reset the timer
            dispatch("autoRefresh");
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            resolve(apiResponse);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    autoRefresh({ state, commit, dispatch }: any) {
      const { token } = state;
      function parseJwt(token: any) {
        if (!token) return;
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
      }

      const { exp } = parseJwt(token);
      const now = Date.now() / 1000;
      let timeUntilRefresh = exp - now;
      timeUntilRefresh -= 5 * 60; // Refresh 5 minutes before it expires
      const resetTokenTaskNumber = setTimeout(
        () => dispatch("resetToken"),
        timeUntilRefresh * 1000
      );
      // save refresh task in order to use in logout
      commit("resetTokenTask", resetTokenTaskNumber);
    },
    usedAutoLogin({ commit }: any) {
      commit("usedAutoLogin");
    },
    clearAutoLoginState({ commit }: any) {
      commit("clearAutoLoginState");
    },
    setAutoLogin({ commit }: any, injectedData: any) {
      commit("setAutoLogin", injectedData);
    },
    loginFromLocalStore({ commit, dispatch }: any, user: any) {
      const userJson = JSON.parse(user);
      commit("authSuccess", {
        token: window.localStorage.getItem("token"),
        ...userJson,
      });
      // fetching all necessary store data
      dispatch("getCompanyUiFields");
      dispatch("getLocations", 1);
      dispatch("fetchAllUsers");
    },
    login({ commit, dispatch }: any, credentials: any) {
      return new Promise((resolve, reject) => {
        axios({ url: ApiUrl.LOGIN, data: credentials, method: "POST" })
          .then((apiResponse: any) => {
            if (apiResponse.request.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              reject(apiResponse);
              return apiResponse;
            }
            const user = apiResponse.data;
            if (apiResponse.data.accessToken) {
              const token = apiResponse.data.accessToken;
              const refreshToken = apiResponse.data.refreshToken;
              user.email = credentials.email;
              localStorage.setItem("token", token);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("user", JSON.stringify(user));
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
              commit("authSuccess", user);
              dispatch("autoRefresh", token);
              // fetching all necessary store data
              dispatch("getCompanyUiFields");
              dispatch("getLocations", 1);
              dispatch("fetchAllUsers");
            } else {
              const companyHandler = {
                isMultipleCompanies: true,
                isFirstLayerAuthorized: true,
                companies: apiResponse.data.companies,
              };
              commit("setFirstLayerUserInfo", user);
              commit("setCompanyHandler", companyHandler);
            }
            resolve(apiResponse);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    loginToCompany({ commit, dispatch }: any, userPayload: any) {
      return new Promise((resolve, reject) => {
        axios({ url: ApiUrl.LOGIN_COMPANY, data: userPayload, method: "POST" })
          .then((apiResponse: any) => {
            if (apiResponse.request.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              reject(apiResponse);
              return apiResponse;
            }
            const user = apiResponse.data;
            const token = apiResponse.data.accessToken;
            const refreshToken = apiResponse.data.refreshToken;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("user", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            commit("authSuccess", user);
            dispatch("autoRefresh", token);
            // fetching all necessary store data
            dispatch("getCompanyUiFields");
            dispatch("getLocations", 1);
            dispatch("fetchAllUsers");
            resolve(apiResponse);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    logout({ state, commit }: any) {
      return new Promise((resolve, _reject) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        delete axios.defaults.headers.common["Authorization"];
        // tell penny log-out has happened
        const event = new Event("TrashButton:loggedOut", {
          bubbles: true,
          cancelable: true,
        });
        window.document.dispatchEvent(event);
        clearTimeout(state.resetTaskNum);
        // clear state
        commit("destroyCompanyState");
        commit("destroyTireState");
        commit("logout");
        resolve(undefined);
      });
    },
    pwForgot({ commit }: any, email: string) {
      return new Promise((resolve, reject) => {
        const data = {
          email: email,
          url: `${window.location.origin}/?token={TOKEN}#/reset-password`,
        };
        axios({ url: ApiUrl.PW_FORGOT, data: data, method: "POST" })
          .then((apiResponse: any) => {
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    pwReset({ commit }: any, data: any) {
      return new Promise((resolve, reject) => {
        axios({ url: ApiUrl.PW_FORGOT, data: data, method: "POST" })
          .then((apiResponse: any) => {
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateProfile({ commit, state }: any, data: any) {
      commit("updateProfile", data);
      window.localStorage.setItem(
        "user",
        JSON.stringify({ ...state.user, ...data })
      );
    },
  },
  getters: {
    isLoggedIn(state: any) {
      return !!state.token;
    },
    isFirstLayerAuthorized(state: any) {
      return state.companyHandler.isFirstLayerAuthorized;
    },
    user(state: any) {
      return state.user;
    },
    userIsUserManager(state: any): boolean {
      return state.user.userIsUserManager;
    },
    userIsAgreementManager(state: any): boolean {
      return state.user.userIsAgreementManager;
    },
    userIsOrderManager(state: any): boolean {
      return state.user.userIsOrderManager;
    },
    userIsLocationManager(state: any): boolean {
      return state.user.userIsLocationManager;
    },
    userIsDocumentManager(state: any): boolean {
      return state.user.userIsDocumentManager;
    },
    userIsDocumentViewer(state: any): boolean {
      return state.user.userIsDocumentViewer;
    },
    userIsExportManager(state: any): boolean {
      return state.user.userIsExportManager;
    },
  },
};
