import Vue from 'vue'
import Vuex from 'vuex'

import { companyModule } from '@/store/company'
import { userModule } from '@/store/user'
import { tireModule } from '@/store/tire'
import { appModule } from '@/store/app'
import { historyModule } from '@/store/history'
import { usermanagementModule } from '@/store/usermanagement'
import { documentModule } from '@/store/document'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: appModule,
    user: userModule,
    company: companyModule,
    tire: tireModule,
    history: historyModule,
    usermanagement: usermanagementModule,
    document: documentModule,
  },
  getters: {
  }
})
