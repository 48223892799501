
import AppDrawer from "@/components/AppDrawer.vue";
import AppUpdate from "@/components/AppUpdate.vue";
import BottomNavigation from "@/components/BottomNavigation.vue";
import FooterDrawer from "@/components/FooterDrawer.vue";
import LocationsLog from "@/components/LocationsLog.vue";
import SubAppHeader from "@/components/SubAppHeader.vue";
import OutDatedBrowser from "@/components/atoms/OutDatedBrowser.vue";
import AppEvents from "@/mixins/AppEvents";
import Vue from "vue";
import { bootstrap } from "vue-gtag";
import { mapGetters } from "vuex";
import MaintenancePage from "./components/MaintenancePage.vue";

export default Vue.extend({
  components: {
    AppDrawer,
    SubAppHeader,
    AppUpdate,
    BottomNavigation,
    FooterDrawer,
    OutDatedBrowser,
    LocationsLog,
    MaintenancePage,
  },
  mixins: [AppEvents],
  data: () => ({
    documentMobileFilter: false,
    navKey: 0,
    mobileNavKey: 1,
    noLoginRequired: false,
    menuStatus: false, //false = inaktiv, true = aktiv
    mainKey: 0,
  }),
  async created() {
    if (this.$cookies.isAccepted()) {
      bootstrap();
    }
    if (window.location.hostname === "app.zentek-digital.biz") {
      window.location.href = "https://connect.zentek.de/";
    }
    await this.$store.dispatch("checkMaintenanceStatus");
    window.localStorage.setItem("orderTab", "");
    const locationHash = window.location.hash;
    if (
      locationHash.includes("join") ||
      locationHash.includes("datenschutz") ||
      locationHash.includes("impressum") ||
      locationHash.includes("kontakt") ||
      locationHash.includes("reset-password")
    ) {
      this.noLoginRequired = true;
    } else {
      this.noLoginRequired = false;
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      inMaintenance: "inMaintenance",
    }),
    appClass() {
      return [
        `rn-${this.$route.name}`,
        `${this.isMobile ? "mobile" : "desktop"}-app`,
        ...(this.isStaging ? ["staging"] : []),
      ];
    },
    inMaintenanceMobile() {
      return this.inMaintenance ? true : false;
    },
    isStaging() {
      const search = new URLSearchParams(window.location.search);
      return !!(
        window.location.hostname.match(
          /^(localhost|.+-w7iv3z4nkq-ez\.a\.run\.app)$/
        ) ||
        (search.has("customBackend") &&
          search
            .get("customBackend")
            ?.match(/.+-w7iv3z4nkq-ez\.a\.run\.app\/?$/))
      );
    },
    routeName() {
      return this.$route.name;
    },
    showLog() {
      return this.$store.getters.showLog;
    },
    showAppDrawer() {
      if (this.inMaintenance) {
        return true;
      } else {
        return this.isLoggedIn || !this.noLoginRequired;
      }
    },
    showMenu() {
      return (
        this.$route.name !== null &&
        this.$route.name !== "" &&
        this.$route.name !== "Login" &&
        (this.$store.getters.user.id > 0 || this.noLoginRequired)
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
    isLocationManager() {
      return this.$store.getters.userIsLocationManager;
    },
    smallerDesktop() {
      return (
        this.$vuetify.breakpoint.width < 1072 &&
        this.$vuetify.breakpoint.width >= 800
      );
    },
  },
  methods: {
    changeMobileFilterFlag(flag: boolean) {
      this.documentMobileFilter = flag;
    },
    setMenuStatusDetail(status: boolean) {
      this.menuStatus = status;
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("checkMaintenanceStatus");
      this.navKey++;
      this.mobileNavKey++;
      this.documentMobileFilter = false;
      const locationHash = window.location.hash;
      if (
        locationHash.includes("join") ||
        locationHash.includes("datenschutz") ||
        locationHash.includes("impressum") ||
        locationHash.includes("kontakt") ||
        locationHash.includes("reset-password") ||
        locationHash.includes("help")
      ) {
        this.noLoginRequired = true;
      } else {
        this.noLoginRequired = false;
      }
      if (this.isLoggedIn !== false) {
        if (this.isLocationManager) {
          this.$store.dispatch("getDeactivatedLocationLog");
        }
      }
    },
    inMaintenance() {
      this.mainKey++;
    },
    isLoggedIn(value: boolean) {
      if (value !== false && this.isLocationManager) {
        this.$store.dispatch("getDeactivatedLocationLog");
      }
    },
    showMenu() {
      this.navKey++;
      this.mobileNavKey++;
    },
  },
});
