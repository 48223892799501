import { EventBus, toastError } from '@/helpers';
import Vue from "vue";

export default Vue.extend({
  created() {
    EventBus.$on("loading", (_: any, data: {isLoading: boolean}) => {
      this.$store.dispatch("setLoadingState", data.isLoading);
    });

    EventBus.$on("tb-logout", () => {
      this.$toast.error(
        "Ihre Session ist abgelaufen. Sie müssen sich erneut anmelden"
      );
      this.$store.dispatch("logout");
      window.setTimeout(() => {
        location.reload();
      }, 2000);
    });

    EventBus.$on("tb-server-error", (err: any) => {
      toastError(this, err, "Leider ist ein interner Server-Fehler aufgetreten. Wir arbeiten daran!");
    });

    EventBus.$on("tb-token-error", (err: any) => {
      toastError(this, err, "Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.");
    });
  }
})
