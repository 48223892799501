<template>
  <v-row :active="isLoading" class="mt-16"></v-row>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "AppDrawerProgressBar",
  props: ["color"],
  computed: {
    ...mapState({
      isLoading: (state) => state.app.isLoading,
    }),
  },
});
</script>
