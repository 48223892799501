import { ApiUrl } from "@/config/ApiUrl";
import { EventBus } from "@/helpers";
import router from "@/router";
import store from "@/store";
import axios from "axios";

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

function onAccessTokenFetched(accessToken: any) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    try {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;
      if (status === 401 || status === 403) {
        if (
          [
            "Token abgelaufen",
            "Ungültige Token-Signatur",
            "Unauthorized",
          ].includes(error.response.data.message)
        ) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            store.dispatch("resetToken").then((res) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(res.data.accessToken);
            });
          }
        } else if (
          error.response.data.message.includes("Invalid credentials")
        ) {
          return Promise.reject(error);
        } else {
          EventBus.$emit("tb-logout");
        }

        return new Promise((resolve) => {
          addSubscriber((accessToken: string) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      }
      if (status == 500) {
        EventBus.$emit("tb-server-error");
      }
    } catch (e) {
      EventBus.$emit("tb-token-error");
    }
    return Promise.reject(error);
  }
);

const pagesViewableWithoutAuth = [
  "/login",
  "/datenschutz",
  "/impressum",
  "/reset-password",
  "/company-login",
  "/join",
];

axios.interceptors.request.use(
  function (config) {
    if (
      pagesViewableWithoutAuth.some((page) =>
        router.currentRoute.path.includes(page)
      )
    ) {
      return config;
    }

    const controller = new AbortController();
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!token || !refreshToken) {
      store.dispatch("logout");
      router.push({ path: "/login" });

      controller.abort();

      return Promise.reject("No token found");
    }

    if (refreshToken) {
      try {
        const { exp: refreshTokenExp } = JSON.parse(
          atob(refreshToken.split(".")[1])
        );

        if (refreshTokenExp < Date.now() / 1000) {
          store.dispatch("logout");
          router.push({ path: "/login" });

          controller.abort();
          return Promise.reject("Refresh token expired");
        }
      } catch (error) {
        console.error("Token parsing error:", error);
      }
    }

    return Promise.resolve(config);
  },
  function (error) {
    // Do something with request error
    // EventBus.$emit('loading', '_', { isLoading: false })
    return Promise.reject(error);
  }
);

interface TableStateInterface {
  tab: number;
  page: number;
}
export const appModule = {
  state: () => ({
    isLoading: false,
    afterLoginTarget: null,
    inMaintenance: false,
    maintenanceEndDate: null,
    maintenanceEndTime: null,
    locationsTable: {
      tab: 0,
      page: 1,
    },
    agreementsTable: {
      tab: 0,
      page: 1,
    },
    ordersTable: {
      tab: 0,
      page: 1,
    },
    usersTable: {
      tab: 0,
      page: 1,
    },
    documentsTable: {
      tab: 0,
      page: 1,
    },
  }),
  mutations: {
    setLoadingState(state: any, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    setAfterLoginTarget(state: any, newTarget: string | null) {
      state.afterLoginTarget = newTarget;
    },
    maintenanceMode(state: any, payload: any) {
      state.inMaintenance = payload.status;
      if (payload.endDate && payload.endDate !== null) {
        const date = new Date(payload.endDate);
        const dateArray = date
          .toLocaleString("de-DE", {
            timeZone: "Europe/Berlin",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
          .split(", ");
        state.maintenanceEndDate = dateArray[0];
        state.maintenanceEndTime = dateArray[1];
      }
    },
    setLocationsTableState(state: any, data: TableStateInterface) {
      state.locationsTable = { ...state.locationsTable, ...data };
    },
    setAgreementsTableState(state: any, data: TableStateInterface) {
      state.agreementsTable = { ...state.agreementsTable, ...data };
    },
    setOrdersTableState(state: any, data: TableStateInterface) {
      state.ordersTable = { ...state.ordersTable, ...data };
    },
    setUsersTableState(state: any, data: TableStateInterface) {
      state.usersTable = { ...state.usersTable, ...data };
    },
    setDocumentsTableState(state: any, data: TableStateInterface) {
      state.documentsTable = { ...state.documentsTable, ...data };
    },
  },
  actions: {
    setLoadingState({ commit }: any, isLoading: boolean) {
      commit("setLoadingState", isLoading);
    },
    setAfterLoginTarget({ commit }: any, newTarget: string | null) {
      commit("setAfterLoginTarget", newTarget);
    },

    async logoutUserIfTokenIsExpired() {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      const userToken = localStorage.getItem("user");

      if (!token || !refreshToken || !userToken) {
        await store.dispatch("logout");
        router.push({ path: "/login" });
        EventBus.$emit("tb-token-error");
      }

      if (refreshToken) {
        try {
          const { exp: refreshTokenExp } = JSON.parse(
            atob(refreshToken.split(".")[1])
          );

          if (refreshTokenExp < Date.now() / 1000) {
            EventBus.$emit("tb-token-error");
            store.dispatch("logout");
            router.push({ path: "/login" });
          }
        } catch (error) {
          console.error("Token parsing error:", error);
        }
      }
    },

    checkMaintenanceStatus({ commit, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.MAINTENANCE_CHECK}`,
          method: "GET",
        })
          .then((apiResponse: any) => {
            if (
              apiResponse.request.status === 401 ||
              apiResponse.request.status === 404 ||
              (apiResponse.response &&
                (apiResponse.response.data.status === 404 ||
                  apiResponse.response.data.statusCode === 404))
            ) {
              reject(apiResponse);
              return apiResponse;
            } else if (apiResponse.data) {
              commit("maintenanceMode", apiResponse.data);
              resolve(apiResponse.data.status);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setLocationsTableState({ commit }: any, data: TableStateInterface) {
      commit("setLocationsTableState", data);
    },
    setAgreementsTableState({ commit }: any, data: TableStateInterface) {
      commit("setAgreementsTableState", data);
    },
    setOrdersTableState({ commit }: any, data: TableStateInterface) {
      commit("setOrdersTableState", data);
    },
    setUsersTableState({ commit }: any, data: TableStateInterface) {
      commit("setUsersTableState", data);
    },
    setDocumentsTableState({ commit }: any, data: TableStateInterface) {
      commit("setDocumentsTableState", data);
    },
  },
  getters: {
    isLoading(state: any) {
      return state.isLoading;
    },
    afterLoginTarget(state: any) {
      return state.afterLoginTarget;
    },
    inMaintenance(state: any) {
      return state.inMaintenance;
    },
    maintenanceEndDate(state: any) {
      return state.maintenanceEndDate;
    },
    maintenanceEndTime(state: any) {
      return state.maintenanceEndTime;
    },
    documentsTableState(state: any) {
      return state.documentsTable;
    },
  },
};
