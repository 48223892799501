
import Vue from "vue";
import { emailRules } from "@/helpers";

const props = {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
};

export default Vue.extend({
  ...props,
  ...{
    data() {
      return {
        pwRequested: false,
        email: "",
        error: "",
        emailRules: emailRules,
      };
    },
    methods: {
      forgotCardTitle() {
        return this.pwRequested
          ? "Verifizierungslink erfolgreich versendet"
          : "Passwort zurücksetzen";
      },
      pwForgot() {
        if (
          // ugly because this.$refs.form.validate() is not typescript ready // missing declaration
          // @see https://stackoverflow.com/questions/52109471/typescript-in-vue-property-validate-does-not-exist-on-type-vue-element/52109899
          !(
            this.$refs.requestForm as Vue & {
              validate: () => boolean;
            }
          ).validate()
        ) {
          return;
        }
        this.$store
          .dispatch("pwForgot", this.email)
          .then(() => {
            this.pwRequested = true;
          })
          .catch(() => {
            this.error =
              "Leider gab es technische Schwierigkeiten bei dieser Anfrage. Bitte kontaktieren sie den Kundenservice.";
          });
      },
    },
  },
});
