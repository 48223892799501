import { ApiUrl } from "@/config/ApiUrl";
import { EventBus } from "@/helpers";
import router from "@/router";
import store from "@/store";
import axios from "axios";


export const usermanagementModule = {
  state: () => ({
    users: [],
    allUsers: [],
  }),
  mutations: {
    saveUsers(state: any, payload: any) {
      state.users = payload;
    },
    saveAllUsers(state: any, payload: any) {
      state.allUsers = payload;
    },
  },
  actions: {
    fetchUsers({ commit }: any) {
      return new Promise((resolve, reject) => {
        axios
          .get(ApiUrl.USERS)
          .then((res: any) => {
            commit("saveUsers", res.data.users);
            resolve(res.data.users);
          })
          .catch(() => {
            EventBus.$toast.error(
              "Hier ist leider ein Fehler aufgetreten. Wir arbeiten daran.."
            );
          });
      });
    },
    fetchAllUsers({ commit }: any) {
      return new Promise((resolve, reject) => {
        axios
          .get(ApiUrl.ALL_USERS)
          .then((res: any) => {
            commit("saveAllUsers", res.data.users);
            resolve(res.data.users);
          })
          .catch((error) => {
            if (error.response && error.response.status === 500) {
              EventBus.$toast.error("Ein interner Server-Fehler ist aufgetreten. Wir arbeiten daran.");
              store.dispatch("logout");
              router.push({ path: '/login' });
            } else {
              EventBus.$toast.error("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
            }

          });
      });
    },
  },
};
