<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    if (window.outdatedBrowserRework)
      window.outdatedBrowserRework()
  }
}
</script>