<template>
  <v-container :class="{'pb-0': true,  'mt-12': isDesktop}">
    <v-row>
      <v-col cols="12" sm="6" md="6" class="pb-0 mx-auto">
        <v-form
          @submit.prevent="sendPassword"
          ref="pwForm"
          v-model="formIsValid"
        >
          <v-card elevation="0">
            <v-card-title>Passwort zurücksetzen</v-card-title>
            <v-card-text v-if="success">
              <p>
                Das Passwort wurde erfolgreich gespeichert. Sie können sich nun
                erneut anmelden.
              </p>
            </v-card-text>
            <v-card-text v-if="!success">
              <p>
                Um ein neues Passwort zu setzen, geben Sie bitte ihr neues
                Passwort zwei mal ein. Anschließend können Sie sich mit dem
                neuen Passwort anmelden.
              </p>

              <v-text-field
                v-disabled-icon-focus
                required
                id="password1"
                label="Neues Passwort"
                :rules="passwdRules"
                prepend-icon="mdi-lock"
                v-model="password1"
                :type="showPw ? 'text' : 'password'"
                :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPw = !showPw"
                class="hide-error-detail"
              ></v-text-field>

              <password-rules :password="password1" />

              <v-text-field
                v-disabled-icon-focus
                required
                id="password2"
                label="Passwort wiederholen"
                :rules="[checkPasswords]"
                prepend-icon="mdi-lock"
                v-model="password2"
                :type="showPw2 ? 'text' : 'password'"
                :append-icon="showPw2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPw2 = !showPw2"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :color="`${formIsValid ? 'primary' : 'default'}`"
                :disabled="!formIsValid"
                v-if="!success"
                >Passwort ändern</v-btn
              >
              <v-btn color="primary" v-if="success" @click="toLogin()"
                >Zur Anmeldung</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script >
import axios from "axios";
import { EventBus, passwdRules, toastError } from "@/helpers";
import { ApiUrl } from "@/config/ApiUrl";
import PasswordRules from "@/components/authentication/PasswordRules.vue";

export default {
  name: "ResetPW",
  components: {
    PasswordRules,
  },
  data: () => {
    return {
      showPw: false,
      showPw2: false,
      password1: "",
      password2: "",
      passwdRules: passwdRules,
      success: false,
      formIsValid: false,
    };
  },
  methods: {
    sendPassword() {
      if (!this.formIsValid) {
        return false;
      }
      const token = window.location.search.split("?")[1].split("=")[1];
      EventBus.$emit("loading", "_", { detail: true });
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.PW_RESET,
            data: {
              token: token,
              password: this.password1,
            },
            method: "POST",
          })
            .then((apiResponse) => {
              this.success = true;
            })
            .catch((e) => {
              toastError(this, e, "Leider ist ein Fehler aufgetreten. :() Bitte wenden Sie sich an uns.");
            });
        }
      });
    },
    toLogin() {
      window.history.replaceState({}, document.title, "/" + "#/login");
      this.$router.go("/");
    },
  },
  computed: {
    checkPasswords() {
      return (
        this.password1 === this.password2 ||
        "Die Passwörter müssen identisch sein."
      );
    },
    isDesktop() {
      return this.$vuetify.breakpoint.width >= 768;
    },
  },
};
</script>

