var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{class:_vm.appClass,attrs:{"id":"inspire"}},[_c('portal-target',{attrs:{"name":"modal-button"}}),_c('meta',{attrs:{"name":"viewport","content":"width=device-width, user-scalable=no, maximum-scale=1, minimum-scale=1"}}),(_vm.showAppDrawer || _vm.inMaintenance)?_c('app-drawer',{key:_vm.navKey,class:_vm.documentMobileFilter ? 'filter-on' : 'filter-off',attrs:{"showMenu":_vm.showMenu}}):_vm._e(),(_vm.isLoggedIn && !_vm.inMaintenance)?_c('sub-app-header',{attrs:{"showMenu":_vm.showMenu,"menuStatus":_vm.menuStatus}}):_vm._e(),(!_vm.inMaintenance || _vm.noLoginRequired)?_c('v-container',{class:{
      'mobile-main-container': _vm.isMobile,
      'main-container': !_vm.isMobile,
    }},[_c('v-main',{staticClass:"main-top-distance",class:{ mobile: _vm.isMobile, desktop: !_vm.isMobile }},[(!_vm.inMaintenance || _vm.noLoginRequired)?_c('router-view',{class:{
          'mx-auto maximum-main': true,
          mobile: _vm.isMobile,
          desktop: !_vm.isMobile,
          'filter-on': _vm.documentMobileFilter,
          'filter-off': !_vm.documentMobileFilter,
        },on:{"mobile-filter":_vm.changeMobileFilterFlag,"menu-status-detail":_vm.setMenuStatusDetail}}):_vm._e(),_c('app-update',{class:{ mobile: _vm.isMobile, desktop: !_vm.isMobile }}),(_vm.isLoggedIn && _vm.showLog)?_c('locations-log'):_vm._e()],1)],1):_vm._e(),(_vm.inMaintenance && !_vm.noLoginRequired)?_c('maintenance-page'):_vm._e(),(!_vm.isMobile)?_c('footer-drawer',{attrs:{"showMenu":_vm.showMenu}}):_vm._e(),(_vm.isMobile && _vm.isLoggedIn && _vm.routeName && !_vm.inMaintenance)?_c('bottom-navigation',{key:_vm.mobileNavKey}):_vm._e(),(_vm.isMobile && _vm.inMaintenance)?_c('div',{class:`mx-auto footer-links`},[_c('v-btn',{staticClass:"my-1 py-2",staticStyle:{"font-size":"0.75rem"},attrs:{"link":"","to":{ name: 'Imprint' },"color":"rgba(0, 0, 0, 0.87)","text":"","rounded":""}},[_vm._v(" Impressum ")]),_c('v-btn',{staticClass:"my-1 py-2",staticStyle:{"font-size":"0.75rem"},attrs:{"link":"","to":{ name: 'Privacy' },"color":"rgba(0, 0, 0, 0.87)","text":"","rounded":""}},[_vm._v(" Datenschutz ")])],1):_vm._e(),_c('out-dated-browser')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }