
import Vue from "vue";

export default Vue.extend({
  name: "CompanyLoginCard",
  props: {
    companyHandler: Object,
  },
  data() {
    return {
      selectedCompany: 0,
    };
  },
  methods: {
    loginToCompany() {
      this.$emit("login-to-company", this.selectedCompany);
    },
  },
});
