<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="720" persistent>
      <v-card style="border-radius: 10px !important">
        <v-card-title
          style="
            display: block !important;
            padding: 4px 16px 0 !important;
            text-align: center !important;
            font-weight: 600 !important;
            color: black !important;
            font-size: 1rem;
          "
          class="more-space text-bold"
          >Standort(e) werden automatisch auf inaktiv gesetzt</v-card-title
        >
        <v-divider />
        <v-container class="pa-0">
          <v-container
            class="pa-0"
            background-color="white"
            color="primary"
            style="
              margin-bottom: 10px;
              box-shadow: 1px 2px 5px -1px #b7b5b5 !important;
            "
          >
            <v-card-text
              style="color: black !important; font-size: 0.8rem !important"
              class="px-8"
            >
              An den folgenden Standorten stehen seit ca. zwei Monaten keine vom
              Entsorger gestellten Behälter und es liegen keine offenen Aufträge
              vor. Um Ihnen eine bessere Übersicht in der Standortauswahl zu
              bieten, haben wir die Standorte automatisch auf inaktiv gesetzt.
              Sie haben die Möglichkeit, inaktive Standorte bei Bedarf in der
              Standortverwaltung wieder auf aktiv zu setzen.</v-card-text
            >
          </v-container>
          <div>
            <v-card
              flat
              style="box-shadow: none; max-width: 720px"
              class="px-1"
            >
              <v-data-table
                dense
                fixed-header
                :sort-by="['alias']"
                :items-per-page="500"
                style="
                  max-width: 720px;
                  max-height: 47vh !important;
                  overflow-y: auto;
                  box-shadow: none !important;
                "
                :headers="[
                  {
                    text: 'Standortname',
                    value: 'alias',
                    sortable: true,
                    class: 'header-locname',
                  },
                  {
                    text: 'Straße',
                    value: 'street',
                    sortable: true,
                    class: 'header-locstreet',
                  },
                  {
                    text: 'Haus-Nr.',
                    value: 'houseNumber',
                    sortable: true,
                    class: 'header-locnumber',
                  },
                  {
                    text: 'PLZ',
                    value: 'zip',
                    sortable: true,
                    class: 'header-loczip',
                  },
                  {
                    text: 'Ort',
                    value: 'city',
                    sortable: true,
                    class: 'header-locs',
                  },
                ]"
                :items="locationLog"
                class="elevation-1 new-normal-font table-locs px-3"
                :hide-default-footer="true"
                sortByText="sad"
              >
              </v-data-table>
            </v-card>
          </div>
          <v-container
            class="pa-0 standard-shadow"
          >
            <div class="text-center pb-4" style="padding-top: 10px !important">
              <v-btn
                type="submit"
                color="primary"
                :min-width="'70%'"
                min-height="50px"
                rounded
                depressed
                @click="accept"
                >Ok, verstanden</v-btn
              >
            </div>
          </v-container>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    accept() {
      this.$store.dispatch("markLocationLogAsRead", 1);
      this.dialog = false;
    },
  },
  computed: {
    locationLog() {
      return this.$store.getters.deactivatedLocationsLog;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
  },
});
</script>
<style lang="scss" scoped>
.standard-shadow {
  z-index: 99;
  -webkit-box-shadow: $standard-webkit-shadow;
  -moz-box-shadow: $standard-moz-shadow;
  box-shadow: $standard-shadow;
  width: 100% !important;
  position: sticky;
}
.table-locs * {
  overflow-x: hidden !important;
}
.table-locs :deep(.header-locs),
.table-locs :deep(.header-locname) {
  color: black !important;
  overflow: hidden !important;
}
.table-locs :deep(.header-locname),
.table-locs :deep(.header-locnumber) {
  min-width: 120px !important;
}

.table-locs :deep(.header-loczip) {
  min-width: 100px !important;
}

.table-locs :deep(.header-locnumber) {
  min-width: 120px !important;
}
.table-locs :deep(td) {
  font-size: 00.75rem !important;
}
</style>
