
import Vue from "vue";
import { CompanyBranch } from "@/types";

export default Vue.extend({
  name: "Contact",
  data: () => {
    return {};
  },
  computed: {
    companyBranch(): CompanyBranch {
      return this.$store.getters.companyBranch;
    },
    href() {
      return `mailto:${this.companyBranch.zentekSupportEmail}`;
    },
  },
});
