var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"720","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"border-radius":"10px !important"}},[_c('v-card-title',{staticClass:"more-space text-bold",staticStyle:{"display":"block !important","padding":"4px 16px 0 !important","text-align":"center !important","font-weight":"600 !important","color":"black !important","font-size":"1rem"}},[_vm._v("Standort(e) werden automatisch auf inaktiv gesetzt")]),_c('v-divider'),_c('v-container',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0",staticStyle:{"margin-bottom":"10px","box-shadow":"1px 2px 5px -1px #b7b5b5 !important"},attrs:{"background-color":"white","color":"primary"}},[_c('v-card-text',{staticClass:"px-8",staticStyle:{"color":"black !important","font-size":"0.8rem !important"}},[_vm._v(" An den folgenden Standorten stehen seit ca. zwei Monaten keine vom Entsorger gestellten Behälter und es liegen keine offenen Aufträge vor. Um Ihnen eine bessere Übersicht in der Standortauswahl zu bieten, haben wir die Standorte automatisch auf inaktiv gesetzt. Sie haben die Möglichkeit, inaktive Standorte bei Bedarf in der Standortverwaltung wieder auf aktiv zu setzen.")])],1),_c('div',[_c('v-card',{staticClass:"px-1",staticStyle:{"box-shadow":"none","max-width":"720px"},attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1 new-normal-font table-locs px-3",staticStyle:{"max-width":"720px","max-height":"47vh !important","overflow-y":"auto","box-shadow":"none !important"},attrs:{"dense":"","fixed-header":"","sort-by":['alias'],"items-per-page":500,"headers":[
                {
                  text: 'Standortname',
                  value: 'alias',
                  sortable: true,
                  class: 'header-locname',
                },
                {
                  text: 'Straße',
                  value: 'street',
                  sortable: true,
                  class: 'header-locstreet',
                },
                {
                  text: 'Haus-Nr.',
                  value: 'houseNumber',
                  sortable: true,
                  class: 'header-locnumber',
                },
                {
                  text: 'PLZ',
                  value: 'zip',
                  sortable: true,
                  class: 'header-loczip',
                },
                {
                  text: 'Ort',
                  value: 'city',
                  sortable: true,
                  class: 'header-locs',
                },
              ],"items":_vm.locationLog,"hide-default-footer":true,"sortByText":"sad"}})],1)],1),_c('v-container',{staticClass:"pa-0 standard-shadow"},[_c('div',{staticClass:"text-center pb-4",staticStyle:{"padding-top":"10px !important"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","min-width":'70%',"min-height":"50px","rounded":"","depressed":""},on:{"click":_vm.accept}},[_vm._v("Ok, verstanden")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }