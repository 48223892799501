import { ApiUrl } from "@/config/ApiUrl";
import axios from "axios";

export const historyModule = {
  state: () => ({
    order: null,
    orderChangeLog: null,
  }),
  mutations: {
    setOrderDetail(state: any, order: any) {
      state.order = order;
    },
    setOrderChangeLog(state: any, orderChangeLog: any) {
      state.orderChangeLog = orderChangeLog;
    },
  },
  actions: {
    async getOrder({ commit, dispatch }: any, orderId: string) {
      const order = await axios.get(`${ApiUrl.ORDER_BY_ID}/${orderId}`);
      if (order.data) {
        commit("setOrderDetail", order.data);
        dispatch("getLocationDetail", order.data.agreement.serviceLocation.id);
      }
    },

    async getOrderChangeLog({ commit }: any, orderId: number) {
      const orderChangeLogs = await axios.get(`${ApiUrl.ORDER_CHANGE_LOG_BY_ID}/${orderId}`);
      if (orderChangeLogs.data) {
        commit("setOrderChangeLog", orderChangeLogs.data);
      }
    },
  },
  getters: {
    order(state: any) {
      return state.order;
    },
    orderChangeLog(state: any) {
      return state.orderChangeLog;
    },
  },
};
