import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4ECE42',
        secondary: '#E5F8F4',
        accent: '#F9C07F',
        error: '#F58686',
        info: '#DDDCE2',
        success: '#E2FFDF',
        warning: '#004E91',
        highlight: '#104CAC',
        textlight: '#666666',
        highlight2: '#012B6F',
        'toast-success': '#000000'
      },
    },
  },
});
