<template>
  <v-subheader
    :inset="inset"
    v-if="!isMobile && showMenu && hasDescription"
    class="subhead-menu"
    :class="{ 'bring-to-front': isBenutzerDetails }"
    style="display: block"
  >
    <div v-if="showBreadcrumb">
      <v-breadcrumbs :items="breadcrumbItems">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="sub-height-max" data-cy="subheader">
      <div style="display: inline-flex">
        <v-toolbar-title
          class="font-weight-bold-menu"
          style="margin-left: 0; margin-right: 10px"
        >
          {{ displayName }}
        </v-toolbar-title>
      </div>
      <div class="ml-1 maximum-width">
        <span
          >{{ displayDescription
          }}<a v-if="ownUserDetail" href="#/settings/account"
            >Kontoeinstellungen.</a
          ></span
        >
      </div>
      <br />
    </div>
    <v-divider />
  </v-subheader>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => {
    return {
      drawer: false,
      tabIndex: 0,
      subMenuList: 0,
      subAvatarList: 0,
      messages: 1,
      inset: true,
      breadcrumbItems: [],
    };
  },
  props: ["showMenu", "menuStatus"],
  watch: {
    showBreadcrumb: {
      deep: true,
      handler: function (value) {
        if (value !== false) {
          if (this.$route.meta.displayName.includes("Standortdetails")) {
            this.breadcrumbItems = [
              {
                text: "Verwaltung",
                disabled: false,
                exact: true,
                to: "/settings/location",
              },
              {
                text: "Standortverwaltung",
                disabled: false,
                exact: true,
                to: "/settings/location",
              },
              {
                text: "Standortdetails",
                disabled: true,
              },
            ];
          } else if (this.$route.meta.displayName.includes("Benutzerdetails")) {
            this.breadcrumbItems = [
              {
                text: "Verwaltung",
                disabled: false,
                exact: true,
                to: "/settings/location",
              },
              {
                text: "Benutzerverwaltung",
                disabled: false,
                exact: true,
                to: "/settings/user/",
              },
              {
                text: "Benutzerdetails",
                disabled: true,
              },
            ];
          } else if (this.$route.name === "EditExternalUser") {
            this.breadcrumbItems = [
              {
                text: "Verwaltung",
                disabled: false,
                exact: true,
                to: "/settings/location",
              },
              {
                text: "Benutzerverwaltung",
                disabled: false,
                exact: true,
                to: "/settings/user/",
              },
              {
                text: "Externer Ansprechpartner",
                disabled: true,
              },
            ];
          } else if (this.$route.meta.displayName === "Auftragsdetails") {
            this.breadcrumbItems = [
              {
                text: "Aufträge",
                disabled: false,
                exact: true,
                to: "/order-history/",
              },
              {
                text: this.$route.params.id,
                disabled: true,
              },
            ];
          }
        }
      },
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
    displayName() {
      if (!this.showMenu) return "";
      return this.$route.meta.displayName
        ? this.$route.meta.displayName
        : "Zentek Connect";
    },
    displayIcon() {
      if (!this.showMenu) return "";
      return this.$route.meta.displayIcon
        ? this.$route.meta.displayIcon
        : "Zentek Connect";
    },
    displayDescription() {
      if (!this.showMenu) return "";
      if (this.$route.meta.displayName.trim() === "Standortdetails") {
        if (this.menuStatus !== true) {
          return "Hier sehen Sie die Standortdetails des gewählten Standortes. Sie haben die Möglichkeit, diese bei Bedarf zu bearbeiten. Zusätzlich können Sie den Standort jederzeit auf aktiv setzen, damit die zugeordneten Benutzer für diesen Standort Behälter bestellen und Entsorgungen beauftragen können.";
        } else {
          return this.$route.meta.displayDescription;
        }
      }

      if (this.$route.name === "EditUser") {
        if (this.menuStatus !== true) {
          return "Hier sehen Sie die Benutzerdetails des gewählten Benutzers. Sie haben die Möglichkeit, diese bei Bedarf zu bearbeiten. Dieser Benutzer ist derzeit inaktiv. Setzen Sie den Nutzer auf aktiv, damit dieser Zentek Connect entsprechend seiner Berechtigungen nutzen kann.";
        } else {
          return this.$route.meta.displayDescription;
        }
      }

      return this.$route.meta.displayDescription
        ? this.$route.meta.displayDescription
        : "Zentek Connect";
    },
    ownUserDetail() {
      return this.$route.name === "OwnUserDetail";
    },
    hasDescription() {
      return this.$route.meta.displayDescription != "";
    },
    smallerDesktop() {
      return this.$vuetify.breakpoint.width < 1072;
    },
    showBreadcrumb() {
      if (this.$route.meta.displayName) {
        if (this.$route.meta.displayName.includes("Standortdetails")) {
          return true;
        } else if (this.$route.meta.displayName.trim() === "Auftragsdetails") {
          return true;
        } else if (this.$route.meta.displayName.includes("Benutzerdetails")) {
          return true;
        } else if (
          this.$route.meta.displayName.trim() ===
          "Externer Ansprechpartner Details"
        ) {
          return true;
        }
      }
      return false;
    },
    isBenutzerDetails() {
      return this.$route.meta.displayName.includes("Benutzerdetails");
    },
  },
});
</script>

<style lang="scss" scoped>
.subhead-menu {
  max-width: 1400px;
  padding-bottom: 20px !important;
  min-height: 152px !important;
  font-size: 0.8rem;
  position: relative;
  top: 75px;
  color: $text-default !important;
  margin: 0 auto;
  width: 100%;

  &.bring-to-front {
    z-index: 2;
  }
}
.v-breadcrumbs {
  padding: 10px 7px 0 0 !important;
}

.sub-height-max {
  min-height: 80px;
  padding-top: 20px;
}

.font-weight-bold-menu {
  font-size: 1.5rem !important;
  font-weight: 400;
}
.v-icon.v-icon {
  &::after {
    background-color: transparent !important;
  }
}
.maximum-width {
  max-width: 860px;
}
@media (max-width: 960px) {
  .subhead-menu {
    font-size: 0.65rem !important;
  }
  .font-weight-bold-menu {
    font-size: 1.2rem !important;
    font-weight: 600;
  }
}
</style>
