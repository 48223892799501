import axios from 'axios'
import { ApiUrl } from '@/config/ApiUrl';

export const tireModule = {
  state: () => ({
    tires: {},
    tireStorage: {},
  }),
  mutations: {
    destroyTireState(state: any) {
      state.tires = {}
      state.tireStorage = {}
    },
    setTires(state: any, payload: any) {
      const result: any = {};
      for (const key in payload) {
        if (payload[key].types.length) {
          result[key] = payload[key];
        }
      }
      state.tires = result
    },
    setTireStorage(state: any, payload: any) {
      state.tireStorage = payload
    },

  },
  actions: {
    getTires({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.TIRE_TYPES,
          data: {},
          method: 'POST'
        })
          .then(apiResponse => {
            commit('setTires', apiResponse.data.diameters)
            resolve(apiResponse)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTireStorage({ commit }: any, locationId: number) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.TIRE_STORAGE,
          data: { serviceLocationId: locationId },
          method: 'POST'
        })
          .then(apiResponse => {
            commit('setTireStorage', apiResponse.data)
            resolve(apiResponse)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addTire({ commit, dispatch, state }: any, data: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.TIRE_STORE,
          data: data,
          method: 'POST'
        })
          .then(apiResponse => {
            dispatch('getTireStorage', data.serviceLocationId)
            resolve(apiResponse)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  getters: {},
}
