import { ApiUrl } from "@/config/ApiUrl";
import { EventBus } from "@/helpers";
import { UserEbenesAndGroups } from "@/interfaces/userManagment";
import {
  ServiceLocation,
  ServiceLocationDetail,
} from "@/model/ServiceLocation";
import { CompanyBranch } from "@/types";
import axios from "axios";

export const companyModule = {
  state: () => ({
    locations: [],
    locationDetail: new ServiceLocationDetail({}),
    locationsOrderInfo: [],
    locationAgreements: [],
    companyHasExternalId: false,
    hasAgreementNr: false,
    hasOrderNr: false,
    hasLocationOrderNr: false,
    hasSecondLocationOrderNr: false,
    hasGLNNumber: false,
    hasShipToNumber: false,
    orders: [],
    orderCount: 0,
    premium: false,
    connectToMynature: true,
    typesForAgreement: {
      agreementTypes: [],
      wasteTypes: [],
      containerTypes: [],
      turnusIntervals: [],
    },
    externalUsers: [],
    invitedUsers: [],
    hasDocuments: false,
    includeInactiveLocations: false,
    deactivatedLocationsLog: [],
    myNaturePartners: [],
    myNatureContracts: [],
    myNatureShipToNumbers: [],
    ebenes: [] as UserEbenesAndGroups[],
    branch: {} as CompanyBranch,
  }),
  mutations: {
    destroyCompanyState(state: any) {
      state.locations = [];
      state.locationDetail = new ServiceLocationDetail({});
      state.orders = [];
      state.orderCount = 0;
      state.myNaturePartners = [];
      state.myNatureContracts = [];
      state.myNatureShipToNumbers = [];
      state.locationsOrderInfo = [];
      state.externalUsers = [];
      state.invitedUsers = [];
      state.branch = {} as CompanyBranch;
    },
    setServiceLocations(state: any, payload: any) {
      state.locations = [];
      state.includeInactiveLocations = false;
      state.myNaturePartners = payload.myNaturePartners;
      state.myNatureContracts = payload.myNatureContracts;
      state.myNatureShipToNumbers = payload.myNatureShipToNumbers;
      state.branch = payload.branch;
      payload.serviceLocations.forEach((location: any) => {
        const loc = new ServiceLocation(location);
        state.locations.push(loc);
        if (loc.active === false) {
          state.includeInactiveLocations = true;
        }
      });
      state.locations.sort(
        (first: ServiceLocation, second: ServiceLocation) => {
          if (first.displayName < second.displayName) {
            return -1;
          }
          if (first.displayName > second.displayName) {
            return 1;
          }
          return 0;
        }
      );
    },
    setServiceLocationDetail(state: any, payload: any) {
      state.orders = [];
      state.orderCount = 0;
      state.locationDetail = new ServiceLocationDetail(payload);
      state.companyHasExternalId =
        payload.company && payload.company.hasExternalId ? true : false;
      if (payload.agreements) {
        payload.agreements.find((agreement: any) => {
          state.orders.push({
            aggreementId: agreement.id,
            orders: agreement.orders,
          });
          state.orderCount += agreement.orders.length;
        });
      }
      state.premium =
        payload.company && payload.company.premium
          ? payload.company.premium
          : false;
      state.connectToMynature =
        payload.company && payload.company.connectToMynature
          ? payload.company.connectToMynature
          : true;
    },
    setCompanyUiFields(state: any, payload: any) {
      if (payload?.agreementNumber && payload.agreementNumber !== "0") {
        state.hasAgreementNr = true;
        state.hasExternalId = true;
      } else {
        state.hasAgreementNr = false;
      }
      if (payload?.documents && payload.documents !== "0") {
        state.hasDocuments = true;
      } else {
        state.hasDocuments = false;
      }
      if (payload?.orderNumber && payload.orderNumber !== "0") {
        state.hasOrderNr = true;
        state.hasExternalId = true;
      } else {
        state.hasOrderNr = false;
      }
      if (payload?.locationOrderNumber && payload.locationOrderNumber !== "0") {
        state.hasLocationOrderNr = true;
        state.hasExternalId = true;
      } else {
        state.hasLocationOrderNr = false;
      }
      if (
        payload?.locationOrderNumberTwo &&
        payload.locationOrderNumberTwo !== "0"
      ) {
        state.hasSecondLocationOrderNr = true;
      } else {
        state.hasSecondLocationOrderNr = false;
      }

      state.hasThirdLocationOrderNr =
        payload?.orderNumberThree && payload.orderNumberThree !== "0";

      if (payload?.locationGLNNumber) state.hasGLNNumber = true;
      else state.hasGLNNumber = false;
      if (payload?.shipToNumber && payload.shipToNumber !== "0") {
        state.hasShipToNumber = true;
      } else {
        state.hasShipToNumber = false;
      }
    },
    setLocationsOrderInfo(state: any, payload: any) {
      state.locationsOrderInfo = payload;
    },
    setTypesForAgreement(state: any, payload: any) {
      state.typesForAgreement = payload;
      state.typesForAgreement.containerTypes.forEach((container: any) => {
        container.label = `${container.size} ${container.type}${
          container.extra ? " " + container.extra : ""
        }`;
        container.sizeLabel = `${container.size} ${
          container.extra ? " " + container.extra : ""
        }`;
      });
    },
    setExternalUserInfo(state: any, payload: any) {
      state.externalUsers = payload.users;
    },
    setInvitedUserInfo(state: any, payload: any) {
      state.invitedUsers = payload.users;
    },
    updateDeactivatedLocationLog(state: any, payload: any) {
      state.deactivatedLocationsLog = payload;
    },
    setLocationAgreements(state: any, payload: any) {
      state.locationAgreements = payload;
    },
    setCompanyEbenesAndGroups(state: any, payload: any) {
      state.ebenes = payload;
    },
  },
  actions: {
    getTypesForAgreement({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.TYPES_AGREEMENT_CREATE,
          method: "GET",
        })
          .then((apiResponse) => {
            commit("setTypesForAgreement", apiResponse.data);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocations({ commit, dispatch }: any, status: number) {
      //1 = active only, 2 = active & inactive
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.GET_LOCATIONS}/${status}`,
          method: "GET",
        })
          .then((apiResponse) => {
            commit("setServiceLocations", apiResponse.data);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocationDetail({ commit }: any, locationId: number) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.GET_LOCATION}/${locationId}`,
          method: "GET",
        })
          .then((apiResponse) => {
            commit("setServiceLocationDetail", apiResponse.data);
            commit("setLocationAgreements", apiResponse.data.agreements);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createOrder({ commit, dispatch, state }: any, data: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.ORDER_CAPTURE,
          data: data,
          method: "POST",
        })
          .then((apiResponse) => {
            dispatch("getLocationDetail", state.locationDetail.id);
            resolve(apiResponse);
          })
          .catch((error) => {
            // @todo this is mucho dirty. containeractionId 1 == abholen, so 422 on big bags would throw this error.
            if (data.containerActionId === 1 && error.response.status == 422) {
              EventBus.$toast.error(
                "Das Lager ist leer. Eine Abholung kann nicht beauftragt werden."
              );
            }
            reject(error);
          });
      });
    },
    createGroupedOrders({ commit, dispatch, state }: any, data: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.CREATE_GROUPED_ORDERS,
          data: data,
          method: "POST",
        })
          .then((apiResponse) => {
            dispatch("getLocationDetail", state.locationDetail.id);
            resolve(apiResponse);
          })
          .catch((error) => {
            // @todo this is mucho dirty. containeractionId 1 == abholen, so 422 on big bags would throw this error.
            if (data.containerActionId === 1 && error.response.status == 422) {
              EventBus.$toast.error(
                "Das Lager ist leer. Eine Abholung kann nicht beauftragt werden."
              );
            }
            reject(error);
          });
      });
    },
    cancelOrder({ commit, dispatch, state }: any, payload: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.ORDER_CANCEL}`,
          data: {
            orderId: payload.orderId,
            cancellationReasonId: Number(payload.cancellationReasonId),
            userInputReason: payload.userInputReason,
          },
          method: "POST",
        })
          .then((apiResponse) => {
            dispatch("getLocationDetail", state.locationDetail.id);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resolveOrder({ commit, dispatch, state }: any, orderId: string) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.ORDER_COMPLETE,
          data: { orderId: orderId },
          method: "POST",
        })
          .then((apiResponse) => {
            dispatch("getLocationDetail", state.locationDetail.id);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    claimOrder({ commit, dispatch, state }: any, claimObject: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.ORDER_CLAIM,
          data: claimObject,
          method: "POST",
        })
          .then((apiResponse) => {
            // @todo instead of just dispatching getLocationDetail, one could write a custom setter
            //commit('setOrderClaimed', claimObject)
            dispatch("getLocationDetail", state.locationDetail.id);

            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCompanyUiFields({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.GET_COMPANY_UI_Fields,
          method: "GET",
        })
          .then((apiResponse) => {
            if (apiResponse.data) {
              commit("setCompanyUiFields", apiResponse.data);
            }
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDeactivatedLocationLog({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.DEACTIVATED_LOCATION_LOG,
          method: "GET",
        })
          .then((apiResponse) => {
            if (apiResponse.data) {
              commit(
                "updateDeactivatedLocationLog",
                apiResponse.data.deactivatedLocations
              );
            }
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    markLocationLogAsRead({ commit, dispatch }: any, companyId: number) {
      commit("updateDeactivatedLocationLog", []);
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.DEACTIVATED_LOCATION_LOG}/${companyId}`,
          method: "patch",
        })
          .then((apiResponse) => {
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocationsOrderInfo({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.GET_LOCATIONS_ORDER_INFO,
          method: "GET",
        })
          .then((apiResponse) => {
            if (apiResponse.data) {
              commit("setLocationsOrderInfo", apiResponse.data);
            }
            resolve(apiResponse);
          })
          .catch((error) => {
            if (error.response.statusCode === 404) {
              resolve(null);
            } else {
              reject(error);
            }
          });
      });
    },
    getExternalContactUsers({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.GET_EXTERNAL_CONTACT_USERS,
          method: "GET",
        })
          .then((apiResponse) => {
            if (apiResponse.data) {
              commit("setExternalUserInfo", apiResponse.data);
            }
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInvitedContactUsers({ commit, dispatch }: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.GET_INVITED_CONTACT_USERS,
          method: "GET",
        })
          .then((apiResponse) => {
            if (apiResponse.data) {
              commit("setInvitedUserInfo", apiResponse.data);
            }
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCompanyEbenesAndGroups({ commit, dispatch }: any, companyId: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: ApiUrl.COMPANY_EBENES_AND_GROUPS,
          method: "GET",
        })
          .then((apiResponse) => {
            commit("setCompanyEbenesAndGroups", apiResponse.data);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    showLog(state: any) {
      return (
        state.deactivatedLocationsLog &&
        state.deactivatedLocationsLog.length > 0
      );
    },
    deactivatedLocationsLog(state: any) {
      return state.deactivatedLocationsLog;
    },
    location(state: any) {
      return state.locationDetail;
    },
    showTireMenu(state: any) {
      return state.locations.find(
        (loc: ServiceLocation) => loc.showTireStorage === true
      );
    },
    locations(state: any) {
      return state.locations;
    },
    includeInactiveLocations(state: any) {
      return state.includeInactiveLocations;
    },
    locationCount(state: any) {
      return state.locations.length;
    },
    locationsInfo(state: any) {
      return state.locationsOrderInfo;
    },
    typesForAgreement(state: any) {
      return state.typesForAgreement;
    },
    companyBranch(state: any) {
      return state.branch;
    }
  },
};
