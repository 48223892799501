<template>
  <div v-if="showMenu || inMaintenance">
    <v-app-bar
      v-if="isMobile && !inMaintenance"
      app
      color="primary mobile"
      elevation="0"
      :class="appBarClassName"
    >
      <meta name="theme-color" content="primary mobile" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <div class="ovals">
        <div class="oval1"></div>
        <div class="oval2"></div>
      </div>

      <div class="app-bar-content">
        <v-app-bar-nav-icon @click.stop="goBack" v-if="!isRootNavigation">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title
          class="font-weight-bold mx-auto"
          :class="{
            'pr-12': !isRootNavigation,
          }"
        >
          {{ displayName }}
        </v-toolbar-title>
      </div>
      <app-drawer-progress-bar color="warning accent-4" slot="extension" />
      <portal-target id="header-portal" name="header" multiple />
    </v-app-bar>
    <v-card
      v-if="!isMobile"
      v-model="drawer"
      app
      :class="
        inMaintenance
          ? 'desktop-menu-no-maintenance-style'
          : 'desktop-menu-style'
      "
    >
      <v-toolbar
        flat
        class="mx-auto"
        v-if="showMenu || inMaintenance"
        style="overflow-wrap: anywhere; max-width: 1400px"
      >
        <router-link :to="{ name: 'Dashboard' }" class="logo-container">
          <div class="logo mr-4"></div>
        </router-link>

        <v-container
          style="width: 850px; max-width: 90%"
          class="pt-1 pb-0"
          v-if="!inMaintenance"
        >
          <v-row class="py-0 my-0" style="width: 100%">
            <div
              class="desk-to-mobile"
              style="display: inline-block"
              v-if="isLoggedIn"
            >
              <v-btn
                @click="clearTabs"
                text
                :to="{ name: 'Dashboard' }"
                class="menu-buttons-nav px-0"
                :class="{ 'menu-font-smaller': smallerDesktop }"
                >Dashboard</v-btn
              ><v-divider
                class="menu-divider"
                style="margin: 0 auto !important"
              />
            </div>
            <div
              class="desk-to-mobile"
              style="display: inline-block"
              v-if="isLoggedIn"
            >
              <v-btn
                @click="clearTabs"
                text
                :to="{ name: 'Agreements' }"
                class="menu-buttons-nav px-0"
                :class="{ 'menu-font-smaller': smallerDesktop }"
                >Beauftragung</v-btn
              ><v-divider
                class="menu-divider"
                style="margin: 0 auto !important"
              />
            </div>
            <div
              class="desk-to-mobile"
              style="display: inline-block"
              v-if="isLoggedIn"
            >
              <v-btn
                text
                :to="{ name: 'OrderHistory' }"
                class="menu-buttons-nav px-0"
                :class="{ 'menu-font-smaller': smallerDesktop }"
                :active-class="'button-active'"
                data-cy="orders"
                >Auftragsübersicht</v-btn
              ><v-divider
                class="menu-divider"
                style="margin: 0 auto !important"
              />
            </div>
            <div
              class="desk-to-mobile"
              style="display: inline-block"
              v-if="isLoggedIn && locationHasTires"
            >
              <v-btn
                text
                @click="clearTabs"
                :to="{ name: 'Tires' }"
                class="menu-buttons-nav px-0"
                :class="{ 'menu-font-smaller': smallerDesktop }"
                >Altreifenlager</v-btn
              ><v-divider class="menu-divider" />
            </div>
            <div
              class="desk-to-mobile setting-menu"
              style="display: inline-block"
              v-if="isLoggedIn"
            >
              <v-menu offset-y open-on-hover content-class="sub-settings">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="clearTabs"
                    text
                    class="menu-buttons-nav px-0"
                    :to="{ name: linkSetting }"
                    :class="{ 'menu-font-smaller': smallerDesktop }"
                    data-cy="hover-verwaltung"
                    >Verwaltung</v-btn
                  >
                </template>
                <v-list flat>
                  <v-list-item-group v-model="subMenuList" color="primary">
                    <v-list-item
                      link
                      :to="{ name: 'LocationSettings' }"
                      v-on:click="linkSetting = 'LocationSettings'"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="menu-sub"
                          data-cy="click-Standortverwaltung"
                          >Standortverwaltung</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="{ name: 'UserList' }"
                      v-on:click="linkSetting = 'UserList'"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="menu-sub"
                          >Benutzerverwaltung</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-divider
                class="menu-divider"
                style="margin: 0 auto !important"
              />
            </div>
            <div
              class="py-0 menu-header"
              style="display: inline-block"
              v-if="isLoggedIn && showDocumentsMenu"
            >
              <v-btn
                text
                :to="{ name: 'Documents' }"
                class="menu-buttons-nav px-0"
                :class="{ 'menu-font-smaller': smallerDesktop }"
                :active-class="'button-active'"
                >Belege</v-btn
              ><v-divider
                class="menu-divider"
                style="margin: 0 auto !important"
              />
            </div>
          </v-row>
        </v-container>

        <v-spacer></v-spacer>

        <v-col
          class="avatar-wrap"
          :class="{ 'menu-font-smaller': smallerDesktop }"
          data-cy="user-avatar"
          v-if="isLoggedIn && !inMaintenance"
        >
          <div class="user-info">
            <span class="name" color="black">{{ name }}</span>
            <span class="user-info-font no-break">{{ email }}</span>
            <span class="user-info-font no-break">{{ companyName }}</span>
          </div>
          <v-menu offset-y bottom open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn icon x-large v-on="on" class="avatar-btn">
                <v-avatar color="warning" class="avatar-border">
                  <v-img
                    v-if="profilePicture && profilePicture.trim() !== ''"
                    alt="Avatar"
                    :src="profilePicture"
                  />
                  <span v-else class="success--text headline">{{
                    initials
                  }}</span>
                </v-avatar>
                <v-icon
                  style="
                    font-size: 1.4rem !important;
                    max-width: 28px !important;
                    max-height: 28px !important;
                    color: rgb(117, 117, 117) !important;
                    background-color: rgb(255, 255, 255) !important;
                    margin-top: 35px;
                    margin-left: -14px;
                    border-radius: 20px;
                  "
                  >mdi-cog-outline</v-icon
                >
              </v-btn>
            </template>
            <v-list flat>
              <v-list-item-group v-model="subAvatarList" color="primary">
                <v-list-item
                  link
                  :to="{ name: 'AccountSettings' }"
                  v-on:click="tabIndex = 3"
                >
                  <v-list-item-content>
                    <v-list-item-title class="menu-sub"
                      >Kontoeinstellungen</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  link
                  :to="{ name: 'Help' }"
                  v-on:click="tabIndex = 4"
                >
                  <v-list-item-content>
                    <v-list-item-title class="menu-sub"
                      >Hilfe</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-on:click="logout()">
                  <v-list-item-content>
                    <v-list-item-title class="menu-sub"
                      >Abmelden</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import AppDrawerProgressBar from "@/components/AppDrawerProgressBar.vue";
import { mapState } from "vuex";

export default Vue.extend({
  data: () => {
    return {
      drawer: false,
      tabIndex: 0,
      subMenuList: 0,
      subAvatarList: 0,
      messages: 1,
      inset: true,
      linkSetting: "",
    };
  },
  mounted() {
    if (window.location.hash.match(/settings\/(user|location|account|)/i)) {
      this.tabIndex = 3; // set active tab magic =)
    }
    this.linkSetting = this.isLocationManager ? "LocationSettings" : "UserList";
  },
  props: ["showMenu"],
  components: {
    AppDrawerProgressBar,
  },
  methods: {
    clearTabs() {
      window.localStorage.setItem("orderTab", "");
    },
    clickingSetting() {
      if (this.isLocationManager) {
        this.$router.push("/settings/location");
      } else {
        this.$router.push("/settings/user");
      }
    },
    logout() {
      this.drawer = false;
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    reloadAction() {
      this.$toast.info(
        "iOS Update-Helper: Bitte beenden Sie nun die App, indem Sie sie im App Drawer Ihres Mobilgerätes weg wischen. Anschließend starten Sie sie die App erneut."
      );
      window.location.reload(true);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapState({
      companyName: (state) => state.user.user.companyName,
      companyHasDocuments: (state) => state.company.hasDocuments,
      userAuthRoleIds: (state) => state.user.user.authRoleIds,
      inMaintenance: (state) => state.app.inMaintenance,
      isLocationManager: (state) => state.user.user.userIsLocationManager,
      isUserManager: (state) => state.user.user.userIsUserManager,
      hasDocumentsRole: (state) =>
        state.user.user.userIsDocumentManager ||
        state.user.user.userIsDocumentViewer,
    }),
    showDocumentsMenu() {
      return this.companyHasDocuments && this.hasDocumentsRole;
    },
    showAdminMenu() {
      return this.isLocationManager || this.isUserManager;
    },
    isRootNavigation() {
      return this.$route.meta.isRootNavigation;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    initials() {
      return (
        this.$store.getters.user.firstName[0] +
        this.$store.getters.user.lastName[0]
      );
    },
    name() {
      return `${this.$store.getters.user.firstName} ${this.$store.getters.user.lastName}`;
    },
    email() {
      return this.$store.getters.user.email.toLowerCase();
    },
    locationHasTires() {
      return this.$store.getters.showTireMenu;
    },
    displayName() {
      if (!this.showMenu) return "";
      return this.$route.meta.displayName
        ? this.$route.meta.displayName
        : "Zentek Connect";
    },
    appBarClassName() {
      return this.$route.meta.subMenu ? "is-submenu" : "is-default";
    },
    displayIcon() {
      if (!this.showMenu) return "";
      return this.$route.meta.displayIcon
        ? this.$route.meta.displayIcon
        : "Zentek Connect";
    },
    displayDescription() {
      if (!this.showMenu) return "";
      return this.$route.meta.displayDescription
        ? this.$route.meta.displayDescription
        : "Zentek Connect";
    },
    smallerDesktop() {
      return (
        this.$vuetify.breakpoint.width < 1200 &&
        this.$vuetify.breakpoint.width >= 800
      );
    },
    profilePicture() {
      return this.$store.getters.user.profilePicture;
    },
  },
});
</script>
<style lang="scss">
.desktop-menu-style {
  position: fixed !important;
  top: 0;
  border-radius: 0 !important;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0 09px -1px #b7b5b5 !important;
  border-bottom: 5px solid $app-highlight !important;
  .v-tab {
    &:not(.v-tab--active) {
      color: black !important;
    }
    padding-right: 4px !important;
    padding-left: 4px !important;
    margin-right: 24px !important;
    font-weight: 500;
  }
  .v-tabs-slider-wrapper {
    bottom: 12px !important;
    height: 3px !important;
  }
  .v-tab--active {
    font-weight: bold !important;
  }
}
.desktop-menu-no-maintenance-style.theme--light.v-sheet {
  background: inherit !important;
}
.desktop-menu-no-maintenance-style {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9;
  box-shadow: none !important;
  .v-toolbar__content {
    background: $app-bg-color !important;
  }
}
.logo-container {
  height: 85%;
  min-width: 150px;
  margin-top: 0.4rem;
}

.logo {
  background-image: url("../assets/ZC_Logo.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  min-width: 120px;
  margin: auto 0;
  height: 100%;
}
.staging {
  .logo {
    &::before {
      content: "TEST";
      transform: rotate(-12deg);
      display: inline-block;
      font-weight: bold;
      font-size: 22px;
      font-family: serif;
      color: orangered;
      text-shadow: 1px 1px maroon;
      white-space: nowrap;
      border: 2px solid orangered;
      border-radius: 4px;
      background-color: rgba(130, 10, 10, 0.3);
      padding: 3px 10px;
      letter-spacing: 3px;
    }
  }
  .mobile {
    .v-toolbar__title {
      overflow: initial;
      &::before {
        content: "TEST";
        transform: rotate(-12deg);
        display: inline-block;
        font-weight: bold;
        font-size: 22px;
        font-family: serif;
        color: orangered;
        text-shadow: 1px 1px maroon;
        white-space: nowrap;
        border: 2px solid orangered;
        border-radius: 4px;
        background-color: rgba(130, 10, 10, 0.3);
        padding: 3px 10px;
        letter-spacing: 3px;
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 15px;
      }
    }
  }
}
.avatar-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-btn {
    position: absolute;
    right: 8px;
    height: 70%;
    .avatar-border {
      border-color: #e7e7e7 !important;
      border-style: solid;
      border-width: 2px;
    }
  }
  .v-btn {
    &:not(.v-btn--text) {
      &:not(.v-btn--outlined) {
        &:hover {
          &::before {
            opacity: 0 !important;
          }
        }
      }
    }
  }
  position: relative;
  padding-right: 72px;
  min-height: 72px;
  max-width: 23%;
}
.drawer-tb {
  margin-left: 5%;
  margin-right: 5%;
}
.setting-menu {
  .menu-divider {
    visibility: hidden;
  }
}
.menu-buttons-nav {
  width: 100% !important;
  height: 100% !important;
  font-size: 0.8rem !important;
  min-width: 20px !important;
  .v-ripple__container {
    display: none !important;
  }
  &:hover {
    .v-btn__content {
      color: $primary !important;
    }
  }
}
.desk-to-mobile {
  padding: 0 20px !important;
  @media (max-width: 850px) {
    padding: 0 14px !important;
  }
  @media (max-width: 900px) {
    padding: 0 10px !important;
  }
}

.menu-buttons-nav.theme--light.v-btn {
  &:hover {
    &::before {
      opacity: 0 !important;
    }
  }
  + {
    .menu-divider {
      visibility: hidden;
    }
  }
}
.menu-buttons-nav.theme--light.v-btn---active {
  &:hover {
    &::before {
      opacity: 0 !important;
    }
  }
}
.menu-buttons-nav.theme--light.v-btn--active {
  &::before {
    opacity: 0 !important;
  }
  color: $primary !important;
  + {
    .menu-divider {
      border-width: thin thick thin;
      border-color: $primary;
      visibility: visible;
      background-color: $primary;
    }
  }
  ~ {
    .menu-divider {
      border-width: thin thick thin;
      border-color: $primary;
      visibility: visible;
      background-color: $primary;
    }
  }
}
.v-tabs-bar.v-item-group {
  > * {
    cursor: initial;
  }
}
.v-tab {
  text-transform: none !important;
  &:hover {
    color: $primary;
  }
}
.v-tabs-bar--is-mobile {
  .v-tab {
    max-width: 100%;
  }
}
.v-list {
  max-width: 100%;
}
.menu-sub.v-list-item__title {
  font-size: 0.85rem !important;
}
.v-list-item--active {
  color: $primary;
}
.user-info {
  display: flex;
  flex-direction: column;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.45rem;
  .name {
    font-weight: bold;
    font-size: 0.875rem !important;
  }
  .user-info-font {
    font-size: 0.75rem !important;
    line-height: 15px;
    color: #757575 !important;
    .no-break {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
header.v-toolbar.mobile {
  background: $app-highlight !important;
}
.v-navigation-drawer--fixed {
  background-color: #fefff9 !important;
}
.v-app-bar {
  height: auto !important;
  //min-height: 126px;
  .ovals {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .oval1 {
      position: absolute;
      width: 200px;
      height: 200px;
      top: -93px;
      left: 42%;
      background: radial-gradient(
        circle at center,
        $app-highlight-2 0,
        transparent 70%
      );
      opacity: 0.7;
      //transform: scaleX(1.5) rotate(45deg);
      z-index: 1;
    }
    .oval2 {
      width: 200px;
      height: 200px;
      top: -36px;
      left: 20%;
      background: radial-gradient(
        circle at center,
        $app-highlight-2 0,
        transparent 70%
      );
      opacity: 0.7;
      position: absolute;
    }
  }

  .app-bar-content {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    z-index: 2;
    padding-bottom: 3px;
    .theme--light.v-icon {
      color: white !important;
    }
  }
  .v-toolbar__extension {
    padding: 0 !important;
    height: 0 !important;
  }

  .v-toolbar.v-toolbar--extended {
    height: 60px !important;
  }
  .v-toolbar__content {
    display: flex;
    flex-direction: column;
  }
}
.hide-me {
  display: none !important;
}
.mobile {
  .v-tabs {
    margin-top: 16px;
  }
  .v-toolbar__content,
  .vue-portal-target {
    z-index: 1;
  }
}

.v-tabs {
  .v-item-group {
    width: 620px;
  }
}
.menu-font-smaller.v-tabs {
  .v-item-group {
    width: 490px;
  }
}
.menu-font-smaller {
  .v-btn__content {
    font-size: 0.75rem !important;
  }
}
.v-slide-group__prev {
  display: none !important;
}
.v-slide-group__next {
  display: none !important;
}
.v-item--active {
  color: $text-default !important;
}
.menu-header {
  padding: 0 17px;
}

@media (max-width: 1200px) {
  .avatar-wrap {
    max-width: 18%;
  }
}
@media (max-width: 1050px) {
  .user-info {
    display: none !important;
  }
}
@media (max-width: 850px) {
  .menu-font-smaller {
    .v-btn__content {
      font-size: 0.725rem !important;
    }
  }
}
.rn-Documents.mobile-app header {
  background: linear-gradient($app-highlight, $app-highlight-2) !important;
}
</style>
