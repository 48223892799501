const search = new URLSearchParams(window.location.search);
const url =
  search.has("customBackend") &&
    (search
      .get("customBackend")
      ?.match(/.*-w7iv3z4nkq-ez\.a\.run\.app\/zentek-connect\/?$/) ||
      search
        .get("customBackend")
        ?.match(/test\.api\.zentek\.dev\/zentek-connect\/?$/) ||
      search
        .get("customBackend")
        ?.match(/test-gcp-migration\.zentek\.dev\/zentek-connect\/?$/))
    ? `https://${search.get("customBackend")}`
    : process.env.VUE_APP_API_URL;

export abstract class ApiUrl {
  public static readonly LOGIN = `${url}/auth/login/credentials`;
  public static readonly LOGIN_COMPANY = `${url}/auth/login/company`;
  public static readonly EMAIL_DUPLICATE = `${url}/user/check-duplicate-email`;
  public static readonly REFRESH_TOKEN = `${url}/auth/create-access-token`;
  public static readonly GET_LOCATIONS = `${url}/get-service-location-list`;
  public static readonly GET_LOCATION = `${url}/service-location`;
  public static readonly EDIT_LOCATION = `${url}/service-location`;
  public static readonly CREATE_LOCATION = `${url}/service-location/create`;
  public static readonly CREATE_EXTERNAL_CONTACT_USER = `${url}/external-contact/create`;
  public static readonly GET_EXTERNAL_CONTACT_USERS = `${url}/users/external`;
  public static readonly GET_INVITED_CONTACT_USERS = `${url}/users/invited`;
  public static readonly ORDER_CAPTURE = `${url}/order/create`;
  public static readonly ORDER_CANCEL = `${url}/order/cancel`;
  public static readonly ORDER_COMPLETE = `${url}/order/complete`;
  public static readonly ORDER_BY_ID = `${url}/order`;
  public static readonly EDIT_ORDER = `${url}/order/edit`;
  public static readonly ORDER_BY_LOCATION_ID = `${url}/service-location/{serviceLocationId}/orders`;
  public static readonly AGREEMENTS_FROM_ORDER_BY_LOCATION_ID = `${url}/service-location/{serviceLocationId}/agreements`;
  public static readonly WASTE_TYPES_FROM_ORDER_BY_LOCATION_ID = `${url}/service-location/{serviceLocationId}/waste-types`;
  public static readonly CONTAINER_ACTIONS_FROM_ORDER_BY_LOCATION_ID = `${url}/service-location/{serviceLocationId}/container-actions`;
  public static readonly ORDER_CLAIM = `${url}/order/claim`;
  public static readonly CLOSE_CLAIM = `${url}/order/close-claim`;
  public static readonly TIRE_TYPES = `${url}/data/storage/tire/getTireTypes`;
  public static readonly TIRE_STORAGE = `${url}/data/storage/tire/getStorage`;
  public static readonly TIRE_STORE = `${url}/data/storage/tire/add`;
  public static readonly PW_FORGOT = `${url}/auth/forgot-password`;
  public static readonly PW_RESET = `${url}/auth/reset-password`;
  public static readonly PW_CHANGE = `${url}/auth/change-password`;
  public static readonly USER_PROFILE = `${url}/account/settings`;
  public static readonly USER_PROFILE_PICTURE = `${url}/account/setting/avatar`;
  public static readonly TYPES_AGREEMENT_CREATE = `${url}/service-catalog`;
  public static readonly AGREEMENT_CREATE = `${url}/agreement/create`;
  public static readonly INQUIRY_CREATE = `${url}/inquiry/create`;
  public static readonly USERS = `${url}/users/registered`;
  public static readonly USER = `${url}/user`;
  public static readonly GET_EXTERNAL_CONTACT_USER_DETAIL = `${url}/external-contact`;
  public static readonly INVITE_USER = `${url}/auth/user/invite`;
  public static readonly REINVITE_USER = `${url}/auth/user/reinvite`;
  public static readonly ACCEPT_INVITATION = `${url}/auth/user/activate`;
  public static readonly EXPORT_AGREEMENTS = `${url}/agreement/report/download`;
  public static readonly EXPORT_ORDERSHISTORY = `${url}/order/report/download`;
  public static readonly SEARCH_ZIPCODE = `${url}/util/search-zip-code`;
  public static readonly SEARCH_ADDRESS = `${url}/util/search-address`;
  public static readonly SEARCH_POSTALCODE = `${url}/util/search-postal-code`;
  public static readonly GET_COMPANY_UI_Fields = `${url}/company-ui-fields`;
  public static readonly GET_LOCATIONS_ORDER_INFO = `${url}/get-locations-order-info`;
  public static readonly EMAIL_SETTINGS = `${url}/account/settings`;
  public static readonly UPDATE_EXTERNAL_USER = `${url}/external-contact`;
  public static readonly GET_GERMAN_HOLIDAYS = `${url}/fetch-german-holidays`;
  public static readonly UPDATE_INVITED_USER = `${url}/users/invited`;
  public static readonly GET_DOCUMENTS = `${url}/docuware/documents`;
  public static readonly GET_SIMPLE_LOCATION_LIST = `${url}/simple-service-location-list`;
  public static readonly EXPORT_WASTE_STATISTICS = `${url}/order/statistik/download`;
  public static readonly GET_CHILDREN_DOCUMENTS = `${url}/docuware/children-documents`;
  public static readonly ALL_USERS = `${url}/users/all`;
  public static readonly DEACTIVATED_LOCATION_LOG = `${url}/log/deactivated-locations`;
  public static readonly MAINTENANCE_CHECK = `${url}/maintenance-check`;
  public static readonly AGREEMENT_CONTAINER_ACTIONS = `${url}/agreement/{agreementId}/container-action`;
  public static readonly DELETE_EXTERNAL_CONTACT_USER = `${url}/external-contact`;
  public static readonly GROUP_IDS_INFO = `${url}/group/{groupId}`;
  public static readonly COMPANY_EBENES_AND_GROUPS = `${url}/company-ebenes-and-groups`;
  public static readonly GET_EBENES = `${url}/ebene`;
  public static readonly CREATE_GROUPED_ORDERS = `${url}/order/create-grouped-orders`;
  public static readonly GET_GROUP_BY_PARTNER_NUMBER = `${url}/get-group-by-partner`;
  public static readonly ORDER_CHANGE_LOG_BY_ID = `${url}/order-change-log`;
  public static readonly CHECK_INVITATION_TOKEN = `${url}/auth/user/check-invitation-token`;
}
