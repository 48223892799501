<template>
  <v-bottom-navigation v-model="value" :class="{ 'tiny-device': isTiny }" fixed>
    <v-btn
      class="bottom-nav-icons"
      value="dashboard"
      :to="{ name: 'Dashboard' }"
      @click="clearTabs"
    >
      <span class="smaller-font">Dashboard</span>

      <v-icon>mdi-view-dashboard-outline</v-icon>
    </v-btn>

    <v-btn
      class="bottom-nav-icons v-btn-vereinbarungen-icon"
      value="recent"
      :to="{ name: 'Agreements' }"
      @click="clearTabs"
    >
      <span class="smaller-font">Beauftragung</span>
      <v-icon>mdi-clipboard-text-outline</v-icon>
    </v-btn>

    <v-btn
      class="bottom-nav-icons"
      value="nearby"
      :to="{ name: 'OrderHistory' }"
    >
      <span class="smaller-font">Auftragsübersicht </span>
      <v-icon>mdi-file-clock-outline</v-icon>
    </v-btn>

    <v-btn
      class="bottom-nav-icons tiny-font"
      value="more"
      :to="{ name: 'MobileSettings' }"
      @click="clearTabs"
    >
      <span class="smaller-font">Mehr</span>
      <v-icon>mdi-dots-horizontal</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  props: ["currentRoute"],
  data: () => ({ value: "" }),
  mounted() {
    if (this.$route.name === "Dashboard") {
      this.value = "dashboard";
    } else if (this.$route.name === "Agreements") {
      this.value = "recent";
    } else if (
      this.$route.name === "OrderHistory" ||
      this.$route.name === "HistoryDetail"
    ) {
      this.value = "nearby";
    } else {
      this.value = "more";
    }
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 120;
        case "sm":
          return 170;
        default:
          return true;
      }
    },
    isTiny() {
      return this.$vuetify.breakpoint.width < 375;
    },
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === "Dashboard") {
        this.value = "dashboard";
      } else if (newRoute.name === "Agreements") {
        this.value = "recent";
      } else if (newRoute.name === "OrderHistory") {
        this.value = "nearby";
      } else {
        this.value = "more";
      }
    },
  },
  methods: {
    fetchLocations() {
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          if (!this.items.length) {
            this.$store.dispatch("getLocations", 1);
          }
        }
      });
    },
    clearTabs() {
      window.localStorage.setItem("orderTab", "");
    },
  },
};
</script>

<style scoped lang="sass">
.v-btn.bottom-nav-icons
  font-size: 10vw
  padding-bottom: 8px !important
.smaller-font
  font-size: 0.65rem !important
  letter-spacing: .025rem
  font-weight: 400
.v-bottom-navigation
  overflow: hidden
  height: 58px !important
.v-item-group.v-bottom-navigation
  box-shadow: $footer-shadow
.v-item-group.v-bottom-navigation .v-btn.v-btn--active
  color: $primary
.theme--light.v-icon
  color: #777 !important
.v-btn--active .theme--light.v-icon
  color: $primary !important

.tiny-device
  .v-icon
    font-size: 8vw
  .tiny-font
    padding: 0 !important
  .v-btn-vereinbarungen-icon
    margin: 0 0 0 15px
</style>
