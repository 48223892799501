<template>
  <v-container class="pb-0">
    <v-row>
      <v-col cols="12" sm="6" md="6" class="pb-0 mx-auto">
        <v-form
          @submit.prevent="sendPassword"
          ref="pwForm"
          v-model="formIsValid"
        >
          <v-skeleton-loader
            v-if="isLoading"
            class="skeleton-class pa-0"
            type="card-heading"
          ></v-skeleton-loader>
          <v-card elevation="0" v-if="!isLoading && isTokenExpired">
            <v-card-title>Einladung abgelaufen</v-card-title>
            <v-card-text>
              <p>
                Die Einladung ist abgelaufen. Bitte wenden Sie sich an den
                Administrator.
              </p>
            </v-card-text>
          </v-card>
          <v-card elevation="0" v-if="!isLoading && !isTokenExpired">
            <v-card-title v-if="success"
              >Sie haben sich erfolgreich registriert</v-card-title
            >
            <v-card-text v-if="success">
              <p>
                Das Passwort wurde gespeichert. Sie können sich nun mit Ihrer
                E-Mail-Adresse anmelden.
              </p>
            </v-card-text>
            <v-card-title v-if="!success">Sie wurden eingeladen</v-card-title>
            <v-card-text v-if="!success">
              <p>
                Um die Einladung anzunehmen, geben Sie bitte Ihr gewünschtes
                Passwort zweimal ein. Anschließend können Sie sich mit Ihrem
                Passwort anmelden.<br />
                Ihr Benutzername ist Ihre E-Mail-Adresse.
              </p>

              <v-text-field
                v-disabled-icon-focus
                required
                id="password1"
                label="Ihr Passwort"
                :rules="passwdRules"
                prepend-icon="mdi-lock"
                v-model="password1"
                :type="showPw ? 'text' : 'password'"
                :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPw = !showPw"
                class="hide-error-detail"
              ></v-text-field>

              <password-rules :password="password1" />

              <v-text-field
                v-disabled-icon-focus
                required
                id="password2"
                label="Passwort wiederholen"
                :rules="[checkPasswords]"
                prepend-icon="mdi-lock"
                v-model="password2"
                :type="showPw2 ? 'text' : 'password'"
                :append-icon="showPw2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPw2 = !showPw2"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :color="`${formIsValid ? 'primary' : 'default'}`"
                :disabled="!formIsValid"
                v-if="!success"
                >Einladung annehmen</v-btn
              >
              <v-btn color="primary" v-if="success" @click="toLogin()"
                >Zur Anmeldung</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script >
import PasswordRules from "@/components/authentication/PasswordRules.vue";
import { ApiUrl } from "@/config/ApiUrl";
import { EventBus, passwdRules } from "@/helpers";
import axios from "axios";
import { toastError } from '../helpers';

export default {
  props: {
    token: String,
  },
  components: { PasswordRules },
  data: () => {
    return {
      showPw: false,
      showPw2: false,
      password1: "",
      password2: "",
      passwdRules: passwdRules,
      success: false,
      formIsValid: false,
      isTokenExpired: false,
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;
    // check if token is valid
    if (!this.token) {
      this.$router.push("/");
    } else {
      axios({
        url: ApiUrl.CHECK_INVITATION_TOKEN,
        data: {
          signupToken: decodeURIComponent(this.token),
        },
        method: "POST",
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response) {
            this.isTokenExpired = true;
          } else {
            toastError(this, e, "Leider ist ein Fehler aufgetreten. :() Bitte wenden Sie sich an uns.");
          }
          this.isLoading = false;
        });
    }
  },
  methods: {
    sendPassword() {
      if (!this.formIsValid) {
        return false;
      }
      EventBus.$emit("loading", "_", { detail: true });
      axios({
        url: ApiUrl.ACCEPT_INVITATION,
        data: {
          signupToken: decodeURIComponent(this.token),
          password: this.password1,
        },
        method: "POST",
      })
        .then(() => {
          this.success = true;
        })
        .catch((e) => {
          toastError(this, e, "Leider ist ein Fehler aufgetreten. :() Bitte wenden Sie sich an uns.");
        });
    },
    toLogin() {
      window.history.replaceState({}, document.title, "/" + "#/login");
      this.$router.go("/");
    },
  },
  computed: {
    checkPasswords() {
      return (
        this.password1 === this.password2 ||
        "Die Passwörter müssen identisch sein."
      );
    },
  },
};
</script>
