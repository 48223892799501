<template>
  <div class="pw-rules">
    <p :class="{ inputIsValid: password.length > 7 }">
      <v-icon> {{ password.length > 7 ? "mdi-check" : "mdi-close" }}</v-icon>
      Mindestens 8 Zeichen
    </p>
    <p :class="{inputIsValid: password.match(/([a-z].*[A-Z]|[A-Z].*[a-z])/),}">
      <v-icon>
        {{password.match(/([a-z].*[A-Z]|[A-Z].*[a-z])/) ? "mdi-check" : "mdi-close" }}</v-icon>
      Groß- und Kleinbuchstaben
    </p>
    <p :class="{ inputIsValid: password.match(/[0-9]/) }">
      <v-icon>
        {{ password.match(/[0-9]/) ? "mdi-check" : "mdi-close" }}
      </v-icon>
      Mindestens 1 Zahl
    </p>
  </div>
</template>
<script>
export default {
  props: ["password"],
};
</script>
<style lang="scss" scoped>
.pw-rules {
  p {
    margin-bottom: 0;
    padding-right: 5px;
    color: $error;
  }
  .v-icon {
    color: $error;
  }
  p.inputIsValid {
    color: $success;
  }
}
.inputIsValid {
  .v-icon {
    color: $success;
  }
}
.hide-error-detail {
  .v-text-field__details {
    display: none;
  }
}
</style>
