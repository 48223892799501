import store from "@/store";
import CompanyLogin from "@/views/CompanyLogin.vue";
import Contact from "@/views/Contact.vue";
import Imprint from "@/views/Imprint.vue";
import Join from "@/views/Join.vue";
import Login from "@/views/Login.vue";
import Privacy from "@/views/Privacy.vue";
import ResetPW from "@/views/ResetPW.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/dashboard",
  },
  {
    path: "/reset-password",
    name: "ResetPW",
    component: ResetPW,
    meta: {
      onlyAnonymous: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      onlyAnonymous: true,
    },
  },
  {
    path: "/impressum",
    name: "Imprint",
    component: Imprint,
    meta: {
      displayIcon: "mdi-registered-trademark",
      displayDescription: "",
    },
  },
  {
    path: "/company-login",
    name: "CompanyLogin",
    component: CompanyLogin,
    meta: {
      requiresAuth: true,
      displayName: "Company Login",
      isRootNavigation: true,
    },
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: Contact,
    meta: {
      displayIcon: "mdi-contacts",
      displayDescription: "",
    },
  },
  {
    path: "/datenschutz",
    name: "Privacy",
    component: Privacy,
    meta: {
      displayIcon: "mdi-bookmark-check-outline",
      displayDescription: "",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      displayName: "Dashboard",
      displayIcon: "mdi-file-clock-outline",
      displayDescription:
        "Hier erhalten Sie eine Übersicht aller Entsorgungen, die heute an Ihren Standorten durchgeführt werden. Über die Karte sehen Sie alle Ihre Standorte. Wählen Sie einen Standort in der Karte aus, um zu sehen, wie viele offene und reklamierte Aufträge es derzeit am gewählten Standort gibt.",
      isRootNavigation: true,
    },
  },
  {
    path: "/agreements",
    name: "Agreements",
    component: () =>
      import(/* webpackChunkName: "agreement" */ "../views/Agreements.vue"),
    meta: {
      requiresAuth: true,
      displayName: "Beauftragung",
      displayIcon: "mdi-clipboard-text-outline",
      displayDescription:
        "Hier sehen Sie alle Behälter inklusiver der darin zu entsorgenden Abfallarten die derzeit am ausgewählten Standort stehen. Wählen Sie einen Behälter aus, um eine neue Entsorgung zu beauftragen oder einen Behälter abzuziehen. Zusätzlich haben Sie die Möglichkeit über den Button “Neuen Behälter bestellen” einen neuen Behälter zu bestellen.",
      isRootNavigation: true,
    },
  },
  {
    path: "/documents",
    name: "Documents",
    component: () =>
      import(/* webpackChunkName: "documents" */ "../views/Documents.vue"),
    meta: {
      requiresAuth: true,
      displayName: "Belege",
      displayIcon: "mdi-clipboard-text-outline",
      displayDescription:
        "Hier erhalten Sie eine Übersicht aller Belege Ihrer Standorte. Mit Hilfe der Filterung haben Sie die Möglichkeit, nach bestimmten Belegen zu filtern. Wählen Sie einen Beleg aus, um dessen Details einzusehen. Zusätzlich können Sie alle einer Rechnung zugehörigen Leistungsbelege einsehen.",
      isRootNavigation: true,
    },
  },
  {
    path: "/tires",
    name: "Tires",
    component: () =>
      import(/* webpackChunkName: "tires" */ "../views/Tires.vue"),
    meta: {
      requiresAuth: true,
      displayName: "Altreifenlager",
      displayIcon: "mdi-warehouse",
      displayDescription:
        "Hier sehen Sie eine Übersicht der eingelagerten Reifen für den ausgewählten Standort. Sie haben die Möglichkeit weitere Reifen einzulagern unter Reifen hinzufügen. Sobald Sie möchten, können Sie die Abholung beauftragen.",
      isRootNavigation: true,
    },
  },
  {
    path: "/order-history",
    name: "OrderHistory",
    component: () =>
      import(
        /* webpackChunkName: "orderhistory" */ "../views/OrderHistory.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Auftragsübersicht",
      displayIcon: "mdi-file-clock-outline",
      displayDescription:
        "Hier erhalten Sie eine Übersicht aller Aufträge eines ausgewählten Standortes. Sie haben die Möglichkeit, bei Bedarf einen offenen zukünftigen Auftrag zu stornieren und einen vergangenen Auftrag auf erledigt zu setzen oder zu reklamieren. Wählen Sie einen Auftrag aus, um dessen Details inklusive seines historischen Verlaufs einzusehen.",
      isRootNavigation: true,
    },
  },
  {
    path: "/order/:id",
    name: "HistoryDetail",
    component: () =>
      import(
        /* webpackChunkName: "historydetail" */ "../views/HistoryDetail.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      displayName: "Auftragsdetails",
      displayIcon: "mdi-file-clock-outline",
      displayDescription:
        "Hier sehen Sie die Details des gewählten Auftrags. Sie haben die Möglichkeit, einen offenen zukünftigen Auftrag zu stornieren oder zu bearbeiten. Bei kurzfristiger Stornierung können Gebühren anfallen. Liegt der offene Auftrag in der Vergangenheit, können Sie diesen bei Bedarf reklamieren. Ein Auftrag eines Umleerbehälters kann nicht bearbeitet werden.",
      subMenu: false, // app bar color: white
    },
  },
  {
    path: "/mobile-settings",
    name: "MobileSettings",
    component: () =>
      import(
        /* webpackChunkName: "mobilesettings" */ "../views/Settings/MobileSettings.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Einstellungen", // app bar title
      subMenu: false, // app bar color: white
      isRootNavigation: true,
    },
  },
  {
    path: "/settings/location",
    name: "LocationSettings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/LocationSetting.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Standortverwaltung", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-map-marker",
      displayDescription:
        "Hier sehen Sie eine Übersicht aller Standorte Ihres Unternehmens. Sie können diese Standorte verwalten sowie neue Standorte anlegen. Zusätzlich haben Sie die Möglichkeit, einen Standort zu deaktivieren, bei dem keine Behälter vor Ort stehen. Wählen Sie einen Standort aus, um dessen Details einzusehen und zu bearbeiten.",
    },
  },
  {
    path: "/help",
    name: "Help",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Help.vue"),
    meta: {
      requiresAuth: true,
      displayName: "Hilfe", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-map-marker",
      displayDescription: "",
    },
  },
  {
    path: "/help/faq",
    name: "FAQ",
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
    meta: {
      requiresAuth: true,
      displayName: "Häufige Fragen", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-map-marker",
      displayDescription: "",
    },
  },
  {
    path: "/settings/location#create",
    name: "LocationCreate",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/LocationSetting.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Standortverwaltung", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-map-marker",
      displayDescription:
        "Hier sehen sie eine Übersicht über alle Standorte, die Ihrem Unternehmen zugeordnet sind. Sie können diese verwalten sowie neue hinzufügen.",
    },
  },
  {
    path: "/settings/location/detail/:id",
    name: "LocationDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "LocationDetail" */ "../components/LocationDetail.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Standortdetails",
      subMenu: false,
      displayDescription:
        "Hier sehen Sie die Standortdetails des gewählten Standortes. Sie haben die Möglichkeit, diese bei Bedarf zu bearbeiten. Dieser Standort ist derzeit aktiv. Um einen aktiven Standort zu deaktivieren, müssen zuvor alle Behälter vom Entsorger abgezogen und alle Vereinbarungen des Standortes geschlossen worden sein.",
    },
  },
  {
    path: "/settings/account",
    name: "AccountSettings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/Account.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Kontoeinstellungen", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-cog",
      displayDescription:
        "Hier sehen Sie Ihre Kontoeinstellungen. Sie haben die Möglichkeit, Ihre persönlichen Daten bei Bedarf anzupassen oder Ihr Passwort zu ändern. Ihre E-Mail-Adresse ist kontogebunden und kann nicht von Ihnen geändert werden. Zusätzlich können Sie bei Bedarf ausgewählte E-Mail-Benachrichtigungen abbestellen.",
    },
  },
  {
    path: "/settings/user",
    name: "UserList",
    component: () =>
      import(
        /* webpackChunkName: "UserList" */ "../views/Settings/UserList.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Benutzerverwaltung", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-box-outline",
      displayDescription:
        "Hier sehen Sie eine Übersicht aller Benutzer und eingeladenen Benutzer Ihres Unternehmens sowie externe Ansprechpartner. Sie können die Benutzer verwalten sowie neue Benutzer einladen. Externe Ansprechpartner sind Kontakte, die Sie als Ansprechpartner bei Standorten hinterlegen können, aber keinen Zugang zu Zentek-Connect haben.",
    },
  },
  {
    path: "/settings/user/edit/:id",
    name: "EditUser",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/EditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Benutzerdetails", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-box-outline",
      displayDescription:
        "Hier sehen Sie die Benutzerdetails des gewählten Benutzers. Sie haben die Möglichkeit diese bei Bedarf zu bearbeiten. Dieser Benutzer ist derzeit aktiv. Setzen Sie den Nutzer bei Bedarf auf inaktiv, damit dieser Zentek Connect nicht mehr nutzen kann.",
    },
  },
  {
    path: "/settings/user/detail/:id",
    name: "OwnUserDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/EditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Benutzerdetails", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-box-outline",
      displayDescription:
        "Hier sehen Sie die Benutzerdetails ihres eigenen Benutzerkontos.Sie sind nicht berechtigt, Ihre eigenen Benutzerdetails zu bearbeiten. Zur Änderung Ihrer persönlichen Daten gehen Sie bitte in Ihre ",
    },
  },
  {
    path: "/settings/invitedUser/edit/:id",
    name: "EditInvitedUser",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/EditUser.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Benutzerdetails", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-box-outline",
      displayDescription:
        "Hier sehen Sie die Benutzerdetails des gewählten Benutzers. Sie haben die Möglichkeit, diese bei Bedarf zu bearbeiten. Dieser Benutzer ist derzeit eingeladen. Es wurde ein Registrierungslink an die angegebene E-Mailadresse gesendet, mit welchem sich der Benutzer erstmalig anmelden kann. Sie können dem Benutzer den Registrierungslink erneut zusenden.",
    },
  },
  {
    path: "/settings/externalUser/edit/:id/:totalLocations",
    name: "EditExternalUser",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings/EditExternalUser.vue"
      ),
    meta: {
      requiresAuth: true,
      displayName: "Externer Ansprechpartner Details", // app bar title
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-box-outline",
      displayDescription:
        "Hier sehen Sie die Details des gewählten externen Ansprechpartners. Sie haben die Möglichkeit, diese bei Bedarf zu bearbeiten. Bei einem externen Ansprechpartner handelt es sich ausschließlich um hinterlegte Kontaktdaten und nicht um registrierte Benutzer, welche die Zentek Connect nutzen.",
    },
  },
  {
    path: "/invite-user",
    name: "InviteUser",
    component: () =>
      import(
        /* webpackChunkName: "inviteuser" */ "../views/Settings/InviteUser.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      displayName: "Benutzer einladen",
      subMenu: false, // app bar color: white
      displayIcon: "mdi-account-arrow-left-outline",
      displayDescription:
        "Hier können Sie neue Benutzer hinzufügen. Ihr/e Kollege/Kollegin erhält im Anschluss eine E-Mail mit einem Registrierungslink, über den er/sie die Registrierung abschließen kann.",
    },
  },
  {
    path: "/join/:token",
    name: "Join",
    component: Join,
    props: true,
    meta: {
      onlyAnonymous: true,
      displayName: "Zentek Connect",
      isRootNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn || (store.getters.isFirstLayerAuthorized && to.name === "CompanyLogin")) {
      next();
      return;
    }
    if (
      to.name === "HistoryDetail" ||
      to.name === "Help" ||
      to.name === "AccountSettings" ||
      to.name === "Agreements"
    ) {
      store.dispatch("setAfterLoginTarget", to.path);
    }
    next("/login");
  } else if (to.matched.some((record) => record.meta.onlyAnonymous)) {
    if (store.getters.isLoggedIn) {
      next("/dashboard");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
