<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="450" persistent>
      <v-card class="pb-5 pt-2"  :class="isMobile ? 'mobile-border': 'desktop-border'">
        <v-card-title class="text-h5"> Cookies </v-card-title>
        <v-card-text>
          Diese Internetseite verwendet Cookies zu Analyse- und Statistikzwecken,
          sowie Google Fonts, um Ihr Nutzererlebnis steigern zu können.
          Genaue Informationen bekommen Sie über unsere <a href="https://connect.zentek.de/#/datenschutz" target="_blank" rel="noopener" style="text-decoration: none;">Datenschutzbestimmung</a>.
          Wenn Sie damit einverstanden sind, freuen wir uns über Ihre Zustimmung.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
          depressed rounded
           color="grey" text @click="decline" > Ablehnen </v-btn>
          <v-spacer />
          <v-btn color="primary" depressed rounded class="px-7" @click="accept" data-cy="click-agree-cookie"> Okay </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
  },
  methods: {
    accept() {
      this.$cookies.accept();
      this.dialog = false;
    },
    decline() {
      this.$cookies.reject();
      this.dialog = false;
    },
  },
});
</script>
<style lang="scss" scoped>
.mobile-border{
  border-radius: 8px !important;
}
.desktop-border{
  border-radius: 10px !important;
}
</style>

